import { DeviceEmergencyCodeEnum } from "lib/enums";
import { Kns } from "lib/types";

interface ReturnType {
  //затопление помещения
  isFlood: boolean;
  //переполнение ямы
  isPitOverflow: boolean;
  //насосы
  isEngines: boolean;
  //датчики уровня
  isSensor: boolean;
  //аварийная кнока
  isEmergencyBtn: boolean;
  // открыта дверь
  isOpen: boolean;
  //на ремонте кнс или нет
  isRepair: boolean;
  //есть ли хоть одна ошибка
  isHasError: boolean;
  //ошибка контроля фаз
  isPhaseControl: boolean;
}

export const useCheckKnsError = (kns: Kns): ReturnType => {
  const isPhaseControl =
    kns.emergencyCode?.includes(DeviceEmergencyCodeEnum.phaseControlError) ??
    false;

  const isFlood =
    kns.emergencyCode?.includes(DeviceEmergencyCodeEnum.roomFlooding) ?? false;

  const isEngines = [
    DeviceEmergencyCodeEnum.enginesLowEfficiency,
    DeviceEmergencyCodeEnum.engine1Overload,
    DeviceEmergencyCodeEnum.engine2Overload,
    DeviceEmergencyCodeEnum.engine3Overload,
  ].some((code) => kns.emergencyCode?.includes(code) ?? false);

  const isPitOverflow =
    kns.emergencyCode?.includes(DeviceEmergencyCodeEnum.pitOverflow) ?? false;

  const isSensor = [
    DeviceEmergencyCodeEnum.lowerSensorError,
    DeviceEmergencyCodeEnum.upperSensorError,
  ].some((code) => kns.emergencyCode?.includes(code) ?? false);

  const isEmergencyBtn =
    kns.emergencyCode?.includes(DeviceEmergencyCodeEnum.emergencyButton) ??
    false;

  const isOpen = kns.reedSwitchInformation;

  return {
    isHasError:
      isFlood ||
      isEngines ||
      isPitOverflow ||
      isSensor ||
      isEmergencyBtn ||
      isPhaseControl,
    isRepair: kns.onRepair,
    isFlood,
    isEngines,
    isPitOverflow,
    isSensor,
    isEmergencyBtn,
    isOpen,
    isPhaseControl,
  };
};
