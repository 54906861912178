export enum ReasonTypeEnum {
  EMERGENCY_REPAIR,
  SCHEDULED_REPAIR,
  TROUBLESHOOTING,
  SCHEDULED_EXAMINATION,
}

export const ReasonType: Record<ReasonTypeEnum, string> = {
  [ReasonTypeEnum.EMERGENCY_REPAIR]: "Аварийный ремонт",
  [ReasonTypeEnum.SCHEDULED_REPAIR]: "Плановый ремонт",
  [ReasonTypeEnum.TROUBLESHOOTING]: "Устранение аварии",
  [ReasonTypeEnum.SCHEDULED_EXAMINATION]: "Плановое обследование",
};
