import React, { FC } from "react";
import { Modal } from "@mui/material";
import { observer } from "mobx-react-lite";

import { journalDuck } from "lib/ducks";
import { JournalContainer } from "containers";

import { StyledBox } from "./styled";

export const ModalKnsJournal: FC = observer(() => {
  const handleClose = () => {
    journalDuck.activeKns = null;
  };

  return (
    <Modal open={!!journalDuck.activeKns} onClose={handleClose}>
      <StyledBox>
        {journalDuck.activeKns && (
          <JournalContainer kns={journalDuck.activeKns} maxHeight="480px" />
        )}
      </StyledBox>
    </Modal>
  );
});
