import { useQuery } from "react-query";
import { knsApi } from "lib/api";

const useFetch = (page: number | undefined, pageSise?: number) => {
  const { isLoading, data, refetch } = useQuery(
    ["fetchKns", page],
    () => knsApi.fetch({ page, pageSise }),
    {
      enabled: Boolean(page),
    },
  );
  return { isLoading, data, refetch };
};

export default useFetch;
