import { styled } from "@mui/material/styles";

export const StyledEngineWorkIndicator = styled("div")(({ theme }) => {
  return {
    background: theme.palette.customGrey.medium,
    width: "5px",
    "&.active": {
      background: theme.palette.system.green.main,
    },
  };
});

export const StyledEngineWorkContainer = styled("div")(({ theme }) => {
  return {
    width: 14,
    height: 10,
    display: "flex",
    border: `1px solid ${theme.palette.customGrey.medium}`,
    borderRadius: "1px",
    padding: "2px",
    "&.reverse": {
      flexFlow: "row-reverse",
    },
  };
});
