import { TextField, TextFieldProps } from "@mui/material";
import React, { FC } from "react";
import { IMaskInput } from "react-imask";

const MASK = [
  {
    mask: "{#} 000 ## 000",
    definitions: {
      "#": /[АВЕКМНОРСТУХ]/,
    },
  },
  {
    mask: "0000 ## 000",
    definitions: {
      "#": /[АВЕКМНОРСТУХ]/,
    },
  },
];

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={MASK}
        //@ts-ignore
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
      />
    );
  },
);

export const LicensePlate: FC<TextFieldProps> = ({ ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
    />
  );
};
