import React, { FC } from "react";
import { Modal } from "@mui/material";
import { observer } from "mobx-react-lite";

import { monitoringDuck } from "lib/ducks";
import { KnsSituationContainer, SituationListContainer } from "containers";

import { StyledBoxSituation, StyledBoxListSituation } from "./styled";
import { Situation } from "lib/types";

export const ModalSituation: FC = observer(() => {
  const handleCloseModal = () => {
    monitoringDuck.knsId = null;
  };

  const handleCloseKns = () => {
    monitoringDuck.activeSituationId = null;
  };

  const handleClick = (situation: Situation) => {
    monitoringDuck.activeSituationId = situation.id;
  };

  return (
    <Modal
      open={!!monitoringDuck.knsId}
      onClose={handleCloseModal}
      sx={{ minWidth: 1800 }}>
      <>
        {monitoringDuck.activeSituationId && (
          <StyledBoxSituation>
            <KnsSituationContainer
              situationId={monitoringDuck.activeSituationId}
              onClose={handleCloseKns}
            />
          </StyledBoxSituation>
        )}

        <StyledBoxListSituation>
          <SituationListContainer
            activeSituationId={monitoringDuck.activeSituationId}
            knsIds={monitoringDuck.knsId}
            onClick={handleClick}
          />
        </StyledBoxListSituation>
      </>
    </Modal>
  );
});
