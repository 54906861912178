import React from 'react';
import { TextFieldProps } from '@mui/material';
import { Location } from '../../lib/types';
import { locationApi } from '../../lib/api';

import AutoCompleteSelect from './AutoCompleteSelect';

type Props = {
  onChange: (
    id: number | number[] | undefined,
    location: Location | undefined,
  ) => void;
} & Omit<TextFieldProps, 'onChange'>;
const Select = (props: Props) => {
  return (
    <>
      <AutoCompleteSelect
        id="locationId"
        name="locationId"
        label="Выберите объект"
        size="small"
        fullWidth
        fetch={locationApi.fetch}
        fetchSingle={locationApi.get}
        {...props}
      />
    </>
  );
};
export default Select;
