import React, { FC } from "react";
import { SvgIconProps } from "@mui/material";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";

type Props = {
  type?: string;
  className?: string;
} & SvgIconProps;
export const WaterContainerIcon: FC<SvgIconProps> = ({
  type,
  className,
  style,
  ...props
}) => {
  let stroke = "none";
  switch (type) {
    case "error":
      stroke = "#EB4343";
      break;
    case "primary":
      stroke = "#6C98DA";
      break;
    default:
      stroke = "#CCD2E3";
  }
  return <CropPortraitIcon sx={{ fontSize: 14 }} {...props} />;
};
