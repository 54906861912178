import { styled } from "@mui/material/styles";
import { Grid, Paper, Box, Typography } from "@mui/material";

export const StyledDiv = styled("div")``;

export const StyledGridFilter = styled(Grid)(({ theme }) => {
  return {
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  };
});

export const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: 14,
  };
});

export const StyledBoxListSituation = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    top: "75px",
    right: "0",
    width: "550px",
    background: theme.palette.background.paper,
    borderRadius: `${theme.spacing(1)} 0px 0px ${theme.spacing(1)}`,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(2),
  };
});

export const StyledBoxSituation = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    top: "75px",
    left: "0",
    width: "900px",
  };
});

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "1200px",
    width: "90%",
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[24],
    padding: theme.spacing(2),
  };
});

export const StyledPaper = styled(Paper)(({ theme }) => {
  return {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    "&.error": {
      border: `2px solid ${theme.palette.error.main}`,
    },
  };
});

export const StyledIconContainer = styled("div")(() => {
  return {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  };
});

export const StyledDeviceCode = styled("div")(() => {
  return {
    fontSize: 32,
    lineHeight: "32px",
    fontWeight: 500,
  };
});

export const StyledItemKns = styled(Grid)(() => {
  return {
    width: 338,
  };
});
