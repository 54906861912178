import React, { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { observer } from "mobx-react-lite";
import { ArrowForwardIos } from "@mui/icons-material";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";

import { StyledInView } from "components/common";
import { dispatcherDuck, journalDuck } from "lib/ducks";
import { useInfinityJournal } from "lib/hooks";
import { Journal, Kns } from "lib/types";
import { localeDate, localeTime } from "lib/helpers";
import { ReasonType } from "lib/enums";

import { StyledJournal, StyledHeadCell, StyledDivScroll } from "./styled";

export const KnsJournal: FC = observer(() => {
  const { ref, inView } = useInView();
  const { active } = dispatcherDuck;

  const journal = useInfinityJournal({
    type: active?.type,
    objectId: active?.id,
  });

  const handleShowJournal = () => {
    if (journalDuck.activeKns) {
      journalDuck.activeKns = null;
    } else {
      journalDuck.activeKns = dispatcherDuck?.active as Kns;
    }
  };

  useEffect(() => {
    if (inView) {
      journal.fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (!journalDuck.activeKns) {
      journal.refetch();
    }
  }, [journalDuck.activeKns]);

  return (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <Stack direction={"row"} spacing={2}>
            <StyledJournal sx={{ pl: 2 }}>Журнал</StyledJournal>
            <IconButton
              size="small"
              onClick={handleShowJournal}
              color={journalDuck.activeKns ? "primary" : "default"}>
              <ArrowForwardIos
                sx={{
                  width: 16,
                  height: 16,
                  rotate: `${journalDuck.activeKns ? 270 : 0}deg`,
                }}
              />
            </IconButton>
          </Stack>
        </Grid>
        {!journalDuck.activeKns && (
          <Grid container item xs={12} sx={{ mt: 1, p: 0.5, pl: 2, pr: 2 }}>
            <StyledHeadCell item xs={3} />
            <StyledHeadCell item xs={3}>
              Причина
            </StyledHeadCell>
            <StyledHeadCell item xs={3}>
              Исполнитель
            </StyledHeadCell>
            <StyledHeadCell item xs={3}>
              Диспетчер
            </StyledHeadCell>
          </Grid>
        )}
      </Grid>
      <StyledDivScroll xs={12}>
        {journalDuck.activeKns &&
        journalDuck.activeKns.deviceCode ===
          dispatcherDuck?.active?.deviceCode ? (
          <Button onClick={handleShowJournal}>Свернуть журнал</Button>
        ) : (
          <>
            {journal.data?.pages.map((page: any) => {
              return page.data.map((journal: Journal, index: number) => {
                return (
                  <Grid
                    key={journal.id}
                    container
                    item
                    xs={12}
                    sx={{
                      p: 0.5,
                      pl: 2,
                      pr: 2,
                      fontSize: 14,
                      bgcolor: index % 2 === 0 ? "primary.light" : "",
                    }}>
                    <Grid item xs={3}>
                      {localeDate(journal?.date ?? "")}{" "}
                      {localeTime(journal?.date ?? "")}
                    </Grid>
                    <Grid item xs={3}>
                      {journal.reason ? ReasonType[journal.reason] : "-"}
                    </Grid>
                    <Grid item xs={3}>
                      {journal?.employee?.firstName ?? "-"}
                    </Grid>
                    <Grid item xs={3}>
                      {journal?.user?.employee?.firstName ?? "-"}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="customGrey.main" component="span">
                        ТС:
                      </Typography>{" "}
                      {journal?.vehicles?.map((veh, index: number) => {
                        const isLast = journal.vehicles.length === index + 1;
                        return (
                          <>
                            {veh.licensePlate?.toLocaleUpperCase()}
                            <Typography
                              color="customGrey.main"
                              component="span">
                              ({veh.startDate && localeTime(veh.startDate)}{" "}
                              {veh.endDate && `- ${localeTime(veh.endDate)}`})
                            </Typography>
                            {!isLast && ","}
                          </>
                        );
                      })}
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      {journal.solution}
                    </Grid>
                  </Grid>
                );
              });
            })}
            <StyledInView ref={ref} />
          </>
        )}
      </StyledDivScroll>
    </>
  );
});
