import React, { FC } from "react";
import { Grid, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

import { dispatcherDuck } from "lib/ducks";
import { Kns } from "lib/types";

import { ListItemIcons } from "./ListItemIcons";
import {
  StyledListContainer,
  StyledKnsItem,
  StyledNameKns,
  StyledStackKns,
} from "./styled";

export const KnsList: FC = observer(() => {
  return (
    <StyledListContainer
      item
      sx={{
        maxHeight: "calc(100vh - 100px)",
        height: "",
        maxWidth: "calc(100% - 512px - 16px)",
        width: "calc(100% - 512px - 16px)",
      }}>
      <Grid container gap={1}>
        {dispatcherDuck.knses.map((kns: Kns) => {
          return (
            <StyledKnsItem
              className={clsx({
                error: dispatcherDuck.checkError(kns),
              })}>
              <StyledStackKns>
                <Stack>
                  <StyledNameKns>{kns.name}</StyledNameKns>
                  <div>{kns.liquidLevel} %</div>
                </Stack>
                <div>
                  <ListItemIcons kns={kns} />
                </div>
              </StyledStackKns>
            </StyledKnsItem>
          );
        })}
      </Grid>
    </StyledListContainer>
  );
});
