import { useMutation } from "react-query";
import { situationApi } from "lib/api";
import { Situation } from "lib/types";

const useAddUpdate = () => {
  const mutation = useMutation((situation: Situation): any => {
    console.log(situation);
    return situation?.id
      ? situationApi.updatePut(situation)
      : situationApi.add(situation);
  });
  return mutation;
};

export default useAddUpdate;
