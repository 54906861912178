import { Kns } from "lib/types";
import { makeAutoObservable } from "mobx";

interface Filter {
  kns?: Kns | null;
}

const INITIAL_FILTER: Filter = {
  kns: null,
};

class Journal {
  // активный кнс
  public activeKns: Kns | null = null;

  constructor() {
    makeAutoObservable(this);
  }
}

const journal = new Journal();

export default journal;
