import React, { useState } from "react";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { ExpandLess, ExpandMore, Dehaze, Logout } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Drawer,
  IconButton,
  Collapse,
  ListItemAvatar,
  Avatar,
  styled,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { settingsDuck } from "../../lib/ducks";
import { AccessType, accessTypeEnum } from "../../lib/enums";

const StyledIconButton = styled(IconButton)`
  position: fixed;
  z-index: 9999;
  margin: 12px 0px 0px 6px;
`;

const SidebarList = styled(List)`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  height: 100vh;
  padding-bottom: 0px;
`;
const SidebarUserItem = styled(ListItem)`
  margin-top: auto;
`;
const Scroll = styled("div")`
  overflow: auto;
  height: calc(100vh - 128px);
`;
const StyledLink = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.primary.main,
  };
});

export const drawerWidth = 250;
const mainLinks = [
  {
    link: "/",
    text: "Главная",
    access: [accessTypeEnum.admin, accessTypeEnum.write, accessTypeEnum.read],
  },
  {
    link: "/dispatcher-journal",
    text: "Журналы",
    access: [accessTypeEnum.admin, accessTypeEnum.write, accessTypeEnum.read],
  },
  {
    link: "/dispatcher-monitoring",
    text: "Мониторинг",
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
  {
    link: "/dispatcher-situation",
    text: "Ситуации",
    access: [accessTypeEnum.admin, accessTypeEnum.write, accessTypeEnum.read],
  },
  {
    isGroup: true,
    showDivider: true,
    label: "Администрирование",
    access: [accessTypeEnum.admin],
    items: [
      {
        link: "/admin/org-units",
        text: "Подразделения",
        access: [accessTypeEnum.admin],
      },
      {
        link: "/admin/employee",
        text: "Сотрудники",
        access: [accessTypeEnum.admin],
      },
      {
        link: "/admin/users",
        text: "Пользователи",
        access: [accessTypeEnum.admin],
      },
      {
        link: "/admin/roles",
        text: "Роли",
        access: [accessTypeEnum.admin],
      },
      {
        isGroup: true,
        label: "Станции",
        access: [accessTypeEnum.admin],
        items: [
          {
            link: "/admin/knses",
            text: "КНС",
            access: [accessTypeEnum.admin],
          },
        ],
      },
      {
        link: "/admin/locations",
        text: "Объекты",
        access: [accessTypeEnum.admin],
      },
    ],
  },
];
type RenderItemProps = {
  isGroup: boolean;
  myKey: string;
  showDivider: boolean;
  handleCollapse: (key: string) => void;
  isOpen: boolean;
  label: string;
  text: string;
  link: string;
  items: any;
  userAccess: AccessType[];
};
const RenderItem = ({
  isGroup,
  myKey,
  showDivider,
  handleCollapse,
  isOpen,
  label,
  text,
  link,
  items,
  userAccess,
}: RenderItemProps) => {
  const navigate = useNavigate();
  let resolved = useResolvedPath(String(link));
  let match = useMatch({ path: resolved?.pathname ?? null, end: true });
  return isGroup ? (
    <React.Fragment key={`render-menu-group-${myKey}`}>
      {showDivider && <Divider key={`divider-${myKey}`} />}
      <ListItem button key={myKey} onClick={() => handleCollapse(link)}>
        <ListItemText key={`text-${myKey}`} primary={label} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={Boolean(isOpen)} timeout="auto" unmountOnExit>
        <List key={`render-menu-list-${myKey}`} component="div" disablePadding>
          <RenderMenu
            myKey={`render-menu-${myKey}`}
            userAccess={userAccess}
            links={items}
          />
        </List>
      </Collapse>
    </React.Fragment>
  ) : (
    <ListItem
      selected={Boolean(match)}
      button
      key={myKey}
      onClick={() => navigate(link)}>
      <ListItemText key={`text-${myKey}`} primary={text} />
    </ListItem>
  );
};
type RenderMenuProps = {
  userAccess: AccessType[];
  links: any;
  myKey: string;
};
const RenderMenu = ({ userAccess, links, myKey }: RenderMenuProps) => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const handleCollapse = (key: string) => {
    setOpen((oldValue) => ({
      ...oldValue,
      [key]: !Boolean(open[key]),
    }));
  };
  return links.map(
    (
      { text, link, isGroup, label, items, showDivider, access }: any,
      index: number,
    ) => {
      return userAccess?.some((userAccess: AccessType) =>
        access.includes(userAccess as unknown as accessTypeEnum),
      ) ? (
        <RenderItem
          key={`render-item-${myKey}-${link}-${index}`}
          isGroup={Boolean(isGroup)}
          myKey={`${myKey}-${link}-${index}`}
          showDivider={showDivider}
          handleCollapse={handleCollapse}
          isOpen={open[link]}
          userAccess={userAccess}
          items={items}
          label={label}
          text={text}
          link={link}
        />
      ) : null;
    },
  );
};
const SideBar = observer(() => {
  const me = settingsDuck.getMe();
  const { hideMenuButterSidebar } = settingsDuck;
  return (
    <>
      {hideMenuButterSidebar !== true && (
        <StyledIconButton
          color="primary"
          onClick={() =>
            settingsDuck.setField("sideBarOpen", !settingsDuck.sideBarOpen)
          }>
          <Dehaze />
        </StyledIconButton>
      )}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            boxShadow: "rgb(0 0 0 / 7%) 6px 0px 10px",
            overflow: "hidden",
          },
        }}
        variant="persistent"
        anchor="left"
        open={settingsDuck.sideBarOpen}
        key="sidebar-drawer">
        <SidebarList key="sidebar-list">
          <Scroll>
            <RenderMenu
              key={"render-menu-f"}
              myKey="render-menu"
              userAccess={me?.access ?? []}
              links={mainLinks}
            />
          </Scroll>
          <SidebarUserItem selected>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={me?.userName} secondary={me?.role?.name} />
            <StyledLink to="/logout">
              <Logout />
            </StyledLink>
          </SidebarUserItem>
        </SidebarList>
      </Drawer>
    </>
  );
});
export default SideBar;
