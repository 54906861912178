import React, { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import clsx from "clsx";

import { EngineWork, StyledTime } from "components/common";
import { PumpIcon } from "components/ui";
import { Kns } from "lib/types";
import { DeviceEmergencyCodeEnum, pumpStatuseEnum } from "lib/enums";

import {
  StyledEngineRow,
  StyledEngineWorkColumn,
  StyledLiquidLevel,
  StyledBtnSituation,
  StyledStack,
  StyledTitleEngine,
  StyledPoint,
  StyledSchemaContainer,
  StyledEngineWorkContainer,
  StyledSchema,
  StyledLevelRow,
  StyledBottomMsg,
} from "./styled";
import { checkDate, getLiquidLevel } from "lib/helpers";

interface Props {
  kns: Kns;
  showBtn?: boolean;
}
export const MemoSchema: FC<Props> = ({ kns, showBtn = false }) => {
  const isLowKpdEngines = kns.emergencyCode.includes(
    DeviceEmergencyCodeEnum.enginesLowEfficiency,
  );

  const isUpperSensorError = kns.emergencyCode.includes(
    DeviceEmergencyCodeEnum.upperSensorError,
  );

  const isLowerSensorError = kns.emergencyCode.includes(
    DeviceEmergencyCodeEnum.lowerSensorError,
  );

  const renderPumps = () => {
    const pumps: ReactNode[] = [];
    for (let idx = 0; idx < kns.pumpsCount; idx++) {
      const status = kns.pumpStatuses[idx];
      const isWork = status === pumpStatuseEnum.on;
      const isLow = kns.emergencyCode.includes(
        // @ts-ignore
        DeviceEmergencyCodeEnum[`engine${idx + 1}Overload`],
      );
      pumps.push(
        <StyledEngineRow key={`memo-scheme-${kns.id}-${idx}`}>
          111
          <PumpIcon
            style={{ width: 41, height: 36 }}
            isOn={isWork}
            isLow={isLow}
            type={clsx({
              primary: isWork,
            })}
          />
          <StyledEngineWorkContainer>
            <div>
              <EngineWork on={status === pumpStatuseEnum.on} />
            </div>
            <div>
              {status === pumpStatuseEnum.off && "Выкл."}
              {status === pumpStatuseEnum.on && "Вкл."}
              {status === pumpStatuseEnum.reserve && "Ожид."}
            </div>
          </StyledEngineWorkContainer>
        </StyledEngineRow>,
      );
    }
    return pumps;
  };

  const liquidLevelMax = 119;
  const liquidLevel = getLiquidLevel(kns, isUpperSensorError);
  const liquidLevelError = liquidLevel > liquidLevelMax;

  return (
    <StyledSchema>
      <Stack direction="row" spacing={1} sx={{ pt: 4 }}>
        <Stack spacing={3} justifyContent="end">
          <StyledLevelRow
            className={clsx({
              error: isUpperSensorError,
            })}>
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <span>3</span>
                <StyledPoint
                  className={clsx({
                    success: kns.liquidLevel > 100,
                    error: isUpperSensorError,
                  })}
                />
              </Stack>
              <span>Уров.</span>
            </Stack>
          </StyledLevelRow>

          <StyledLevelRow
            className={clsx({
              error: isUpperSensorError,
            })}>
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <span>2</span>
                <StyledPoint
                  className={clsx({
                    success: kns.middleBeacon,
                  })}
                />
              </Stack>
              <span>Уров.</span>
            </Stack>
          </StyledLevelRow>

          <StyledLevelRow
            className={clsx({
              error: isLowerSensorError,
            })}>
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <span>1</span>
                <StyledPoint
                  className={clsx({
                    success: kns.lowBeacon,
                    error: isLowerSensorError,
                  })}
                />
              </Stack>
              <span>Уров.</span>
            </Stack>
          </StyledLevelRow>

          {kns.updated && (
            <StyledLevelRow
              sx={{
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: 10,
              }}>
              Время:
              <StyledTime align="center">{checkDate(kns.updated)}</StyledTime>
            </StyledLevelRow>
          )}

          {showBtn && <StyledBtnSituation>Ситуация</StyledBtnSituation>}
        </Stack>

        <Stack spacing={1.5}>
          <StyledLiquidLevel
            className={clsx({
              error: liquidLevelError,
            })}
            sx={{ mb: 1 }}>
            {liquidLevel} %
          </StyledLiquidLevel>
          <StyledSchemaContainer
            className={clsx({
              error: isUpperSensorError || liquidLevelError,
              error1: isLowerSensorError,
            })}
            load={liquidLevel}></StyledSchemaContainer>
        </Stack>

        <StyledStack
          className={clsx({
            error: isLowKpdEngines,
          })}
          spacing={2}
          justifyContent="space-between"
          sx={{ pb: 1, width: 100 }}>
          <StyledTitleEngine
            className={clsx({
              error: isLowKpdEngines,
              isLowKpdEngines,
            })}>
            {isLowKpdEngines ? "Низкий КПД насосов" : "Штатный режим"}
          </StyledTitleEngine>
          <StyledEngineWorkColumn>{renderPumps()}</StyledEngineWorkColumn>
        </StyledStack>
      </Stack>
      {liquidLevelError || isLowerSensorError || isUpperSensorError ? (
        <StyledBottomMsg>
          <span>{liquidLevelError ? "Переполнение" : ""}</span>
          <span>
            {isLowerSensorError || isUpperSensorError
              ? "Ошибка датчика уровня"
              : ""}
          </span>
        </StyledBottomMsg>
      ) : null}
    </StyledSchema>
  );
};
