import React, { FC, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { format } from "date-fns";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Stack, TextField } from "@mui/material";

import { Employee, JournalVehicle } from "lib/types";
import { SituationStatusEnum } from "lib/enums";

import { StyledStackItem } from "./styled";
import { EmployeeSelect } from "./EmployeeSelect";
import { Status } from "./Status";
import { ModalVehicles } from "./ModalVehicles";

const schema = yup.object({
  employeeId: yup.number().nullable().required(),
  solution: yup.string().nullable().required("Обязательное поле"),
});

interface Props {
  vehicles: JournalVehicle[];
  solution: string | null;
  startDate: string | Date;
  employee: Employee | undefined | null;
  employeeId: number | undefined | null;
  onSubmit: (
    value: { employeeId: number; vehicles: JournalVehicle[]; solution: string },
    deleteVehicle: number[],
  ) => void;
  loading: boolean;
  onChangeStatus: (status: SituationStatusEnum) => Promise<void>;
  status: SituationStatusEnum;
}
export const InnerForm: FC<Props> = ({
  vehicles,
  employeeId,
  employee,
  startDate,
  onSubmit,
  loading,
  onChangeStatus,
  status,
  solution,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteVeh, setDeleteVeh] = useState<number[]>([]);

  const handleSubmit = (value: any) => {
    onSubmit(value, deleteVeh);
  };

  const readOnly = [
    SituationStatusEnum.CLOSED,
    SituationStatusEnum.RESOLVED,
  ].includes(status);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate,
      employee,
      employeeId,
      solution,
      vehicles,
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const handleChangeEmployee = (
    id: number | number[] | undefined,
    employee: Employee | undefined,
  ) => {
    formik.setFieldValue("employee", employee);
    formik.setFieldValue("employeeId", id);
  };

  const onChangeVehicles = (
    vehicles: JournalVehicle[],
    deleteVehicle: number[],
  ) => {
    formik.setFieldValue("vehicles", vehicles);
    setDeleteVeh(deleteVehicle);
    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ModalVehicles
        open={open}
        onClose={onClose}
        vehicles={vehicles}
        onSubmit={onChangeVehicles}
        readOnly={readOnly}
      />
      <form
        key="journal-form"
        onSubmit={formik.handleSubmit}
        style={{ width: "100%" }}>
        <Grid container item xs={12} justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <StyledStackItem>
              {format(new Date(formik.values.startDate), "dd.mm.yy")}
            </StyledStackItem>
            <StyledStackItem grey>
              {format(new Date(formik.values.startDate), "hh:mm")}
            </StyledStackItem>
            <StyledStackItem>
              <EmployeeSelect
                readOnly={readOnly}
                employee={formik.values?.employee}
                employeeId={formik.values?.employeeId}
                onChange={handleChangeEmployee}
                error={Boolean(formik.errors.employeeId)}
              />
            </StyledStackItem>
          </Stack>
          <Status
            status={status}
            onChangeStatus={onChangeStatus}
            loading={loading}
            readOnly={readOnly}
          />
        </Grid>
        <Grid container sx={{ overflow: "auto", maxHeight: 132 }}>
          <Grid container item xs={12} paddingTop={2}>
            <TextField
              label="Меры по устранению"
              size="small"
              fullWidth
              variant={readOnly ? "standard" : "outlined"}
              disabled={readOnly}
              name="solution"
              value={formik.values?.solution}
              onChange={formik.handleChange}
              error={formik.touched.solution && Boolean(formik.errors.solution)}
              helperText={formik.touched.solution && formik.errors.solution}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} paddingTop={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{ width: "100%", alignItems: "center" }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="contained" size="small" onClick={onOpen}>
                {readOnly ? "просмотр" : "добавить тс"}{" "}
                {formik.values.vehicles.length
                  ? `(${formik.values.vehicles.length})`
                  : null}
              </Button>
            </Stack>
            {!readOnly && (
              <LoadingButton
                loading={loading}
                size="small"
                variant="contained"
                type="submit">
                Сохранить
              </LoadingButton>
            )}
          </Stack>
        </Grid>
      </form>
    </>
  );
};
