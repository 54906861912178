import React, { FC } from "react";
import { Button, Grid, Stack, Tooltip } from "@mui/material";
import { clsx } from "clsx";

import { Kns } from "lib/types";
import {
  UnlockIcon,
  LockIcon,
  Divider,
  EngineIcon,
  FloodIcon,
  NotifAlarmIcon,
  SensorIcon,
  WaterContainerIcon,
  ElectrIcon,
} from "components/ui";
import { DeviceWorkStatus, MemoSchema } from "components/common";

import {
  StyledItemKns,
  StyledDeviceCode,
  StyledIconContainer,
  StyledPaper,
  StyledTypography,
  StyledDiv,
} from "./styled";
import { journalDuck, monitoringDuck } from "lib/ducks";
import { useCheckKnsError } from "lib/hooks";

interface Props {
  kns: Kns;
}
export const KnsItem: FC<Props> = ({ kns }) => {
  const {
    isFlood,
    isPitOverflow,
    isEngines,
    isSensor,
    isEmergencyBtn,
    isOpen,
    isPhaseControl,
    isHasError,
  } = useCheckKnsError(kns);

  const handleShowJournal = () => {
    journalDuck.activeKns = kns;
  };

  const handleShowSituation = () => {
    monitoringDuck.knsId = kns.id;
  };

  return (
    <StyledItemKns item>
      <StyledPaper
        className={clsx({
          error: isHasError,
        })}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              <StyledDeviceCode>{kns.deviceCode}</StyledDeviceCode>
              <StyledDiv
                sx={{
                  maxWidth:
                    230 -
                    (isEmergencyBtn ? 0 : 14) -
                    (isEngines ? 0 : 14) -
                    (isPitOverflow ? 0 : 14) -
                    (isSensor ? 0 : 14) -
                    (isFlood ? 0 : 14) -
                    (isPhaseControl ? 0 : 14),
                }}>
                <StyledTypography>{kns.location.shortAddress}</StyledTypography>
                <StyledTypography sx={{ color: "customGrey.main" }}>
                  {kns.organizationUnit.name}
                </StyledTypography>
              </StyledDiv>
              {isOpen ? (
                <UnlockIcon sx={{ fontSize: 20 }} color="primary" />
              ) : (
                <LockIcon sx={{ fontSize: 20 }} color="disabled" />
              )}
              <DeviceWorkStatus workStatus={kns.workStatus} />
              <StyledIconContainer>
                {isFlood && (
                  <Tooltip title="Затопление помещения" placement="top">
                    <span>
                      <FloodIcon color="error" />
                    </span>
                  </Tooltip>
                )}
                {isEngines && (
                  <Tooltip
                    title="Низкая произвдительность насосов"
                    placement="top">
                    <span>
                      <EngineIcon type="error" />
                    </span>
                  </Tooltip>
                )}
                {isEmergencyBtn && (
                  <Tooltip title="Кнопка аварии" placement="top">
                    <span>
                      <NotifAlarmIcon color="error" />
                    </span>
                  </Tooltip>
                )}
                {isSensor && (
                  <Tooltip title="Ошибка датчика" placement="top">
                    <span>
                      <SensorIcon color="error" />
                    </span>
                  </Tooltip>
                )}
                {isPitOverflow && (
                  <Tooltip title="Переполнение ямы" placement="top">
                    <span>
                      <WaterContainerIcon color="error" />
                    </span>
                  </Tooltip>
                )}
                {isPhaseControl && (
                  <Tooltip title="Ошибка контродя фаз" placement="top">
                    <span>
                      <ElectrIcon type="error" />
                    </span>
                  </Tooltip>
                )}
              </StyledIconContainer>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <MemoSchema kns={kns} />
        </Grid>
        <Grid container>
          <Divider sx={{ width: "100%", mt: 3, mb: 1 }} />
          <Stack direction="row-reverse" spacing={2.5} sx={{ width: "100%" }}>
            {/* <Button variant="outlined" sx={{ flexGrow: 1 }}>
              Карточка
            </Button>*/}
            <Button variant="outlined" onClick={handleShowSituation}>
              Ситуации
            </Button>
            <Button variant="outlined" onClick={handleShowJournal}>
              Журнал
            </Button>
          </Stack>
        </Grid>
      </StyledPaper>
    </StyledItemKns>
  );
};
