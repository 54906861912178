import { styled } from "@mui/material/styles";

export const StyledStatus = styled("div")(({ theme }) => {
  return {
    background: "#f8f8fb",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(1),
    color: theme.palette.customGrey.medium,
    gap: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&.error": {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
  };
});
