import React, { FC } from "react";
import { useOrgUnits } from "lib/hooks";
import { OrgUnit } from "lib/types";

import { StyledTabs, StyledTab } from "./styled";

interface Props {
  value: number | null;
  onChange: (_event: any, organizationUnitId: number | null) => void;
}

export const OrgUnitTabs: FC<Props> = ({ value, onChange }) => {
  const { data: orgUnits } = useOrgUnits(1);
  return (
    <StyledTabs
      variant="scrollable"
      scrollButtons="auto"
      value={value}
      onChange={onChange}>
      <StyledTab label="Все" value={null} />
      {(orgUnits?.data ?? []).map(({ name, id }: OrgUnit) => {
        return <StyledTab label={name} value={id} key={`org-unit-${id}`} />;
      })}
    </StyledTabs>
  );
};
