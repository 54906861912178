import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { settingsDuck } from "../../lib/ducks";

const StyledHeader = styled("div")`
  display: flex;
  align-items: center;
  padding: 16px;
  background: white;
  box-shadow: rgb(0 0 0 / 7%) 6px 0px 10px;
`;
const Left = styled("div")<{
  open?: boolean;
}>(({ open }) => {
  return {
    ...(!open && { marginLeft: 40 }),
  };
});

const Right = styled("div")`
  margin-left: auto;
`;
type Props = {
  title: string;
  right?: React.ReactNode;
};
const Header = ({ title, right }: Props) => {
  return (
    <StyledHeader>
      <Left open={settingsDuck.sideBarOpen}>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
      </Left>
      <Right>{right}</Right>
    </StyledHeader>
  );
};
export default Header;
