import React, { FC, useState } from "react";

import { SituationStatus, SituationStatusEnum } from "lib/enums";
import { Edit, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, IconButton } from "@mui/material";
import { StyledStackItem } from "./styled";

interface Props {
  readOnly: boolean;
  loading: boolean;
  status: SituationStatusEnum;
  onChangeStatus: (status: SituationStatusEnum) => Promise<void>;
}
export const Status: FC<Props> = ({
  status,
  onChangeStatus,
  readOnly,
  loading,
}) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const toogleShowEdit = () => {
    setShowEdit((old) => !old);
  };

  const handleChangeStatus = (status: SituationStatusEnum) => () => {
    onChangeStatus(status);
    toogleShowEdit();
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <StyledStackItem>
        Текущий статус:{" "}
        {!showEdit && (
          <Typography
            sx={{ ml: 1 }}
            color={readOnly ? "success.main" : "primary.main"}>
            {SituationStatus[status].toLowerCase()}
          </Typography>
        )}
      </StyledStackItem>
      {!showEdit && !readOnly && (
        <IconButton size="small" onClick={toogleShowEdit}>
          <Edit fontSize="small" />
        </IconButton>
      )}
      {showEdit && (
        <>
          {[SituationStatusEnum.DRAFT].includes(status) && (
            <LoadingButton
              loading={loading}
              onClick={handleChangeStatus(SituationStatusEnum.WORK)}
              size="small"
              variant="outlined"
              color="warning">
              В работе
            </LoadingButton>
          )}
          {[SituationStatusEnum.DRAFT].includes(status) && (
            <LoadingButton
              loading={loading}
              onClick={handleChangeStatus(SituationStatusEnum.CLOSED)}
              size="small"
              variant="outlined"
              color="success">
              Закрыть
            </LoadingButton>
          )}
          {[SituationStatusEnum.WORK].includes(status) && (
            <LoadingButton
              loading={loading}
              onClick={handleChangeStatus(SituationStatusEnum.RESOLVED)}
              size="small"
              variant="outlined"
              color="success">
              Решено
            </LoadingButton>
          )}
          <IconButton size="small" onClick={toogleShowEdit}>
            <Close fontSize="small" />
          </IconButton>
        </>
      )}
    </Stack>
  );
};
