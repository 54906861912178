import { List, Form } from "../../pages/Kns";
import { accessTypeEnum } from "../../lib/enums";

const knses = [
  {
    component: List,
    path: "/admin/knses",
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: "/admin/knses/new",
    access: [accessTypeEnum.admin],
  },
  {
    component: Form,
    path: "/admin/knses/:id/edit",
    access: [accessTypeEnum.admin],
  },
];
export default knses;
