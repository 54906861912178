import { Stack, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { StyledSituationHeightContainer } from "components/common";

export const StyledSituationListContainer = styled(Grid)(({ theme }) => {
  return {
    background: theme.palette.background.paper,
    width: 512,
    height: "calc(100vh - 100px)",
    borderRadius: `${theme.spacing(1)} 0px 0px ${theme.spacing(1)}`,
    overflow: "auto",
    padding: theme.spacing(2),
  };
});

export const StyledStackKns = styled(Stack)(() => {
  return {
    justifyContent: "space-between",
    height: "100%",
  };
});

export const StyledNameKns = styled("div")(({ theme }) => {
  return {
    color: theme.palette.black.main,
  };
});

export const StyledKnsItem = styled(Grid)(({ theme }) => {
  return {
    width: 160,
    height: 160,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.customGrey.main}`,
    color: theme.palette.customGrey.main,
    background: theme.palette.system.green.light,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    "&.error": {
      background: theme.palette.error.light,
      borderColor: theme.palette.error.main,
    },
  };
});

export const StyledListContainer = styled(StyledSituationHeightContainer)(
  ({ theme }) => {
    return {
      alignSelf: "flex-start",
      width: `calc(100% - 512px - ${theme.spacing(2)})`,
      marginRight: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: `0px ${theme.spacing(1)} ${theme.spacing(1)}  0px`,
    };
  },
);
