import { Vns } from "../types";
import { CommonApi, CommonApiMethod } from ".";
import { deviceWorkStatusEnum } from "lib/enums";

const MOCK_DATA = {
  workStatus: deviceWorkStatusEnum.auto,
  emergencyCode: [0],
  inPressureLevel: 0,
  outPressureLevel: 0,
  reedSwitchInformation: false,
  pressureLevelIn: 0,
  pressureLevelOut: 0,
  motorsCount: 0,
  motorsStatuses: [],
};

const convertDataFromServer = (vns: Vns): Vns => {
  const data =
    Array.isArray(vns.vnsMessages) && vns.vnsMessages.length !== 0
      ? vns.vnsMessages[0]
      : MOCK_DATA;
  return {
    ...vns,
    ...data,
    dateUpdate: new Date(),
  };
};

const route = "vns";
const vns: CommonApiMethod = new CommonApi<Vns>(
  route,
  "ВНС",
  undefined,
  convertDataFromServer,
);

export default vns;
