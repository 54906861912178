import React, { FC } from "react";
import { ErrorIcon, GreenPointIcon, RepairIcon } from "components/ui";
import { useCheckKnsError } from "lib/hooks";
import { Kns } from "lib/types";

interface Props {
  kns: Kns;
}
export const KnsIcon: FC<Props> = ({ kns }) => {
  const { isRepair, isHasError } = useCheckKnsError(kns);
  return (
    <>
      {isRepair ? (
        <RepairIcon />
      ) : isHasError ? (
        <ErrorIcon />
      ) : (
        <GreenPointIcon />
      )}
    </>
  );
};
