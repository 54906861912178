import React, { FC } from "react";
import { Avatar as MuiAvatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { deviceWorkStatusEnum } from "lib/enums";

export const StyledAvatar = styled(MuiAvatar)<{ color: "primary" | "success" }>(
  ({ theme, color = "primary" }) => {
    const common = {
      width: theme.spacing(2),
      height: theme.spacing(2),
      fontSize: 10,
      border: `1px solid ${theme.palette[color].main}`,
    };

    if (color === "success") {
      return {
        ...common,
        background: theme.palette[color].main,
        color: "white",
      };
    }
    return {
      ...common,
      background: "transparent",
      color: theme.palette[color].main,
    };
  },
);

interface Props {
  workStatus?: deviceWorkStatusEnum;
}
export const DeviceWorkStatus: FC<Props> = ({ workStatus = "primary" }) => {
  return (
    <StyledAvatar
      color={
        workStatus === deviceWorkStatusEnum.manual ? "success" : "primary"
      }>
      {workStatus === deviceWorkStatusEnum.auto && "А"}
      {workStatus === deviceWorkStatusEnum.dispatch && "Д"}
      {workStatus === deviceWorkStatusEnum.manual && "Р"}
    </StyledAvatar>
  );
};
