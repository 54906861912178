import React, { useEffect } from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { getQueryParams, setQueryParams } from "query-params-util";

type Props = DataGridProps & {
  hanleChangePage: (page: number) => void;
};
const BaseDataGrid = ({
  page,
  rows,
  rowCount,
  hanleChangePage,
  ...props
}: Props) => {
  const onPageChange = (value: number) => {
    const page = value + 1;
    setQueryParams({ page });
    hanleChangePage(page);
  };
  useEffect(() => {
    const { page } = getQueryParams();
    if (Number.isInteger(Number(page))) {
      hanleChangePage(Number(page));
    } else {
      hanleChangePage(1);
    }
  }, []);
  return (
    <DataGrid
      disableSelectionOnClick
      disableDensitySelector
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      onPageChange={onPageChange}
      pageSize={20}
      paginationMode="server"
      page={page ? page - 1 : undefined}
      rows={Array.isArray(rows) ? rows : []}
      rowCount={rowCount ?? 0}
      {...props}
    />
  );
};
export default BaseDataGrid;
