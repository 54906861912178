import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)(({ theme }) => {
  return {
    minHeight: theme.spacing(4),
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: 0.3,
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      background: theme.palette.primary.light,
      color: `${theme.palette.black.main} !important`,
      borderRadius: theme.spacing(1),
    },
  };
});

export const StyledTab = styled(Tab)(({ theme }) => {
  return {
    textTransform: "none",
    fontSize: "14px",
    color: theme.palette.black.main,
    minHeight: theme.spacing(4),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    minWidth: 50,
  };
});
