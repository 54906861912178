import React, { FC } from "react";
import { Kns } from "lib/types";
import { Grid, Stack } from "@mui/material";
import clsx from "clsx";

import {
  NotifAlarmIcon,
  SensorIcon,
  ElectrIcon,
  EngineIcon,
  ConnectIcon,
  FloodIcon,
} from "components/ui";
import { useCheckKnsError } from "lib/hooks";
import { StyledStatus } from "./styled";

interface Props {
  kns: Kns;
  spacing?: number;
}
export const KnsErrorContainer: FC<Props> = ({ kns, spacing = 3 }) => {
  const { isEmergencyBtn, isSensor, isEngines, isFlood, isPhaseControl } =
    useCheckKnsError(kns);
  return (
    <>
      <Grid item xs={6}>
        <Stack spacing={spacing}>
          <StyledStatus
            className={clsx({
              error: isEmergencyBtn,
            })}>
            <NotifAlarmIcon color={isEmergencyBtn ? "error" : "disabled"} />
            <span className="label">
              {isEmergencyBtn ? "Авар. кнопка" : "Кнопка не нажата"}
            </span>
          </StyledStatus>

          <StyledStatus
            className={clsx({
              error: isSensor,
            })}>
            <SensorIcon color={isSensor ? "error" : "disabled"} />
            <span className="label">Датчик уровня</span>
          </StyledStatus>

          <StyledStatus
            className={clsx({
              error: isPhaseControl,
            })}>
            <ElectrIcon type={isPhaseControl ? "error" : "disabled"} />
            <span className="label">
              {isPhaseControl ? "Ошибка фаз" : "Фазы в норме"}
            </span>
          </StyledStatus>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack spacing={spacing}>
          <StyledStatus
            className={clsx({
              error: isEngines,
            })}>
            <EngineIcon type={isEngines ? "error" : "disabled"} />
            <span className="label">
              {isEngines ? "Низкий КПД" : "Насосы в норме"}
            </span>
          </StyledStatus>

          <StyledStatus>
            <ConnectIcon />
            <span className="label">Нет связи</span>
          </StyledStatus>

          <StyledStatus
            className={clsx({
              error: isFlood,
            })}>
            <FloodIcon color={isFlood ? "error" : "disabled"} />
            <span className="label">{isFlood ? "Затопление КНС" : "Сухо"}</span>
          </StyledStatus>
        </Stack>
      </Grid>
    </>
  );
};
