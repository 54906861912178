import React, { FC, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";

import {
  JournalContainer,
  KnsInfoContainer,
  SkeletonContainer,
} from "containers";
import { MemoSchema, StyledSituationViewContainer } from "components/common";

import {
  ReasonTypeEnum,
  FacilityTypeEnum,
  SituationStatusEnum,
  SkeletonTypeEnum,
} from "lib/enums";
import { situationDuck } from "lib/ducks";
import { Journal, JournalVehicle } from "lib/types";
import {
  useSituation,
  useJournal,
  useAddUpdateJournal,
  useAddUpdateJournalVehicle,
  useDeleteJournalVehicle,
  useAddUpdateSituation,
} from "lib/hooks";

import {
  StyledBorderBottomRight,
  StyledJournalTitle,
  StyledBorderBottom,
  StyledDivContainer,
  InnerForm,
} from "./components";

interface Props {
  onClose: () => void;
  situationId: number;
}
export const KnsSituationContainer: FC<Props> = ({ onClose, situationId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    data: situation,
    refetch: refetchSituation,
    isFetching: isFetchingSituation,
    isLoading: isLoadingSituation,
  } = useSituation(situationId);
  const {
    data: journal,
    refetch: refetchJournal,
    isFetching,
    isLoading,
  } = useJournal(situation?.journalId ?? null);
  const mutationJournal = useAddUpdateJournal();
  const mutationJournalVehicle = useAddUpdateJournalVehicle();
  const deleteJournalVehicle = useDeleteJournalVehicle();
  const mutationSituation = useAddUpdateSituation();

  const updSituation = async (upd: any) => {
    return mutationSituation
      .mutateAsync({
        ...situation,
        ...upd,
      })
      .then((situation) => {
        return situation;
      })
      .catch(() => {
        toast.error("Не удалось обновить ситуацию");
        return null;
      });
  };

  const addUpdJornal = async (journal: Journal) => {
    return mutationJournal
      .mutateAsync(journal)
      .then(async (journal: Journal) => {
        await updSituation({ journalId: journal?.id });
        return journal?.id ?? null;
      })
      .catch(() => {
        toast.error("Не удалось создать запись журнала");
        return null;
      });
  };

  const handleSubmit = async (
    {
      employeeId,
      vehicles,
      solution,
    }: { employeeId: number; vehicles: JournalVehicle[]; solution: string },
    deleteVehicle: number[],
  ) => {
    try {
      setLoading(true);
      // создаем/редактируем запись в журнале
      const journalId = await addUpdJornal({
        date: new Date().toISOString(),
        ...(journal ?? {}),
        employeeId,
        type: FacilityTypeEnum.KNS,
        reason: String(ReasonTypeEnum.TROUBLESHOOTING),
        solution,
        objectId: situation.knsId,
        knsId: situation.knsId,
      } as any as Journal);

      // добавляем/удаляем/редактируем ТС в журнале
      let promises: any = [];
      if (journalId) {
        vehicles.forEach((vehicle) => {
          journalId &&
            promises.push(
              mutationJournalVehicle.mutateAsync({
                ...vehicle,
                journalId,
              }),
            );
        });
        deleteVehicle.forEach((id) => {
          promises.push(deleteJournalVehicle.mutateAsync(id));
        });
      }

      await Promise.all(promises);

      if (situation.journalId) {
        await refetchJournal();
      } else {
        await refetchSituation();
      }
      situationDuck.refetchSituationListContainer = true;
    } catch (error) {
      toast.error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = async (status: SituationStatusEnum) => {
    try {
      setLoading(true);
      await updSituation({ status });
      await refetchSituation();
      situationDuck.refetchSituationListContainer = true;
    } catch (e) {
      toast.error("Не удалось сменить статус у ситуации");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkeletonContainer
      skeleton={SkeletonTypeEnum.SITUATION_CARD}
      count={1}
      isLoading={isLoadingSituation || situation === undefined}>
      {situation ? (
        <StyledSituationViewContainer item>
          <Grid container item xs={12} justifyContent="right">
            <IconButton onClick={onClose} size="small" sx={{ p: 0 }}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>
          <Grid container>
            <Grid container>
              <StyledBorderBottomRight item xs={7}>
                <KnsInfoContainer kns={situation.kns} />
              </StyledBorderBottomRight>
              <StyledBorderBottom item xs={5} sx={{ paddingInline: 2 }}>
                <MemoSchema kns={situation.kns} />
              </StyledBorderBottom>
            </Grid>
          </Grid>
          <StyledDivContainer>
            <StyledBorderBottom container paddingTop={2}>
              <InnerForm
                onChangeStatus={handleChangeStatus}
                status={situation.status}
                loading={loading || isFetching || isLoading}
                startDate={situation.startDate}
                onSubmit={handleSubmit}
                employee={journal?.employee}
                employeeId={journal?.employeeId}
                vehicles={journal?.vehicles ?? []}
                solution={journal?.solution ?? null}
              />
            </StyledBorderBottom>

            <Grid container paddingTop={2}>
              <JournalContainer
                title={<StyledJournalTitle>Журнал объекта</StyledJournalTitle>}
                kns={situation.kns}
                maxHeight="calc(100vh - 470px - 332px)"
              />
            </Grid>
          </StyledDivContainer>
        </StyledSituationViewContainer>
      ) : (
        <SkeletonContainer
          isLoading={true}
          skeleton={SkeletonTypeEnum.SITUATION_CARD}
        />
      )}
    </SkeletonContainer>
  );
};
