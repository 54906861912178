import React, { FC } from "react";
import { MapContainer as LeafletContainer, TileLayer } from "react-leaflet";
import { Container, MapComponent } from "./components";

interface Props {
  disablePoint?: boolean;
}
export const MapContainer: FC<Props> = ({ disablePoint = false }) => {
  return (
    <Container>
      <LeafletContainer
        style={{ height: "100vh" }}
        center={[55.838591, 48.508112]}
        zoom={13}
        zoomControl={false}>
        {!disablePoint && <MapComponent />}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LeafletContainer>
    </Container>
  );
};
