import React, { FC, useState, useEffect } from "react";
import {
  TablePagination as MuiTablePagination,
  TablePaginationProps,
} from "@mui/material";

type PaginationProps = {
  pageSize: number | undefined;
  totalCount: number;
  page: number | undefined;
} & Omit<TablePaginationProps, "count" | "page" | "rowsPerPage">;

export const TablePagination: FC<PaginationProps> = ({
  totalCount,
  pageSize = 20,
  page = 1,
  ...props
}) => {
  const [random, setRandom] = useState("");

  useEffect(() => {
    setRandom(`${Math.floor(Math.random() * 100)}`);
  }, []);

  const currentPage = Number.isInteger(Number(page)) ? Number(page) - 1 : 0;

  return (
    <MuiTablePagination
      key={`pagination-${random}`}
      count={totalCount}
      sx={{ borderTop: 1, borderColor: "customGrey.border" }}
      component="div"
      showFirstButton
      showLastButton
      labelRowsPerPage="Показывать по:"
      rowsPerPageOptions={[5, 10, 20, 50]}
      labelDisplayedRows={({ from, to, count }) => {
        return `${from}–${to} из ${count !== -1 ? count : `более чем ${to}`}`;
      }}
      SelectProps={{
        inputProps: {
          "aria-label": "rows per page",
        },
      }}
      page={currentPage}
      rowsPerPage={pageSize}
      {...props}
    />
  );
};
