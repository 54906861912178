import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const StyledError = styled(Grid)(({ theme }) => {
  return {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.error.light}`,
  };
});

export const StyledBtnContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  };
});

export const StyledMemoSchemaContainer = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "center",
  };
});

export const StyledSituationShortInfo = styled("div")(({ theme }) => {
  return {
    position: "fixed",
    background: theme.palette.background.paper,
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(1.5),
    zIndex: theme.zIndex.modal,
    boxShadow: theme.shadows[1],
    borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    width: "405px",
    marginLeft: "44px",
    marginTop: "8px",
  };
});

export const StyledAlertItemTitle = styled("div")(({ theme }) => {
  return {
    color: theme.palette.error.main,
    justifyContent: "right",
    textTransform: "uppercase",
    fontSize: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    flexGrow: "1",
  };
});

export const StyledAlertItemTime = styled("div")(() => {
  return {
    textAlign: "right",
  };
});

export const StyledAlertItemIcon = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
  };
});

export const StyledAlertItemName = styled("div")(({ theme }) => {
  return {
    width: theme.spacing(5),
    textAlign: "right",
  };
});

export const StyledAlertItemContainer = styled("div")(({ theme }) => {
  return {
    borderRadius: `${theme.spacing(1)} 0px 0px ${theme.spacing(1)}`,
    background: theme.palette.error.light,
    lineHeight: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative",
    cursor: "pointer",
    fontSize: 14,
    "& .showHover": {
      visibility: "hidden",
      opacity: 0,
      transition: "visibility 0s, opacity 0.3s linear",
    },
    "&:hover": {
      boxShadow: theme.shadows["1"],
    },
    "&:hover .showHover": {
      visibility: "visible",
      opacity: 1,
    },
  };
});

export const StyledDivContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    color: theme.palette.black.main,
    zIndex: theme.zIndex.snackbar,
    gap: theme.spacing(1),
    height: "220px",
    overflow: "auto",
    width: 484,
    top: 70,
    right: 0,
  };
});
