import { styled } from "@mui/material/styles";

export const MarkerIconContainer = styled("div")`
  display: flex;
  text-align: center;
  padding: 8px 16px;
  cursor: pointer;
  &.active {
    background: #e5f3fb;
    border-radius: 12px;
  }
`;

export const Container = styled("div")(({ theme }) => {
  return {
    position: "fixed",
    width: "100%",
    top: 0,
  };
});
