import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const StyledInView = styled("div")(() => {
  return {
    height: 1,
  };
});

export const StyledTime = styled(Typography)(({ theme }) => {
  return {
    fontSize: theme.spacing(1.5),
    fontWeight: 700,
  };
});
