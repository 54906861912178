import { useQuery } from "react-query";
import { situationApi } from "lib/api";

const useGet = (id: number) => {
  const { isLoading, data, isFetching, refetch } = useQuery(
    ["getSituation", id],
    () => {
      return situationApi.get(id);
    },
    {
      enabled: Boolean(id),
    },
  );
  return { isLoading, data, isFetching, refetch };
};

export default useGet;
