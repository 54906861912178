import { styled } from "@mui/material/styles";
import { Button, Grid, Stack, Typography } from "@mui/material";

export const StyledEngineRow = styled("div")(({ theme }) => {
  return {
    fontSize: 14,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  };
});

export const StyledEngineWorkColumn = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gap: theme.spacing(1.5),
    flexDirection: "column",
    minHeight: "186px",
    justifyContent: "flex-end",
  };
});

export const StyledDiv = styled("div")(({ theme }) => {
  return {
    fontSize: 14,
    paddingInline: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
  };
});

export const StyledLiquidLevel = styled(StyledDiv)(({ theme }) => {
  return {
    background: theme.palette.system.grey,
    height: 27,
    marginInline: theme.spacing(1),
    justifyContent: "center",
    "&.error": {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
  };
});

export const StyledTitleEngine = styled("div")(({ theme }) => {
  return {
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: theme.spacing(1.5),
    "&.error": {
      color: theme.palette.error.main,
    },
    "&.isLowKpdEngines": {
      fontSize: "14px",
    },
  };
});

export const StyledStack = styled(Stack)(({ theme }) => {
  return {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.customGrey.main}`,
    paddingInline: theme.spacing(1),
    "&.error": {
      border: `1px solid ${theme.palette.error.main}`,
    },
  };
});

export const StyledBtnSituation = styled(Button)(({ theme }) => {
  return {
    textTransform: "none",
    color: theme.palette.customGrey.medium,
    paddingInline: theme.spacing(1.5),
    paddingBlock: theme.spacing(2.5),
    border: `1px solid ${theme.palette.customGrey.medium}`,
  };
});

export const StyledPoint = styled("span")(({ theme }) => {
  return {
    height: 11,
    width: 11,
    borderRadius: "50%",
    background: theme.palette.customGrey.medium,
    "&.success": {
      background: theme.palette.success.main,
    },
    "&.error": {
      background: theme.palette.error.main,
    },
  };
});

export const StyledLevelRow = styled("div")(({ theme }) => {
  return {
    minWidth: 56,
    fontSize: 14,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.light}`,
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    "&.error": {
      border: `1px solid ${theme.palette.error.main}`,
    },
  };
});

export const StyledSchema = styled("div")(({ theme }) => {
  return {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  };
});

export const StyledEngineWorkContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  };
});

export const StyledSchemaContainer = styled(Grid)<{ load?: number }>(
  ({ theme, load = 0 }) => {
    return {
      width: 97,
      zIndex: 99,
      position: "relative",
      display: "flex",
      alignItems: "end",
      height: 202,
      borderLeft: `4.5px solid ${theme.palette.customGrey.main}`,
      borderRight: `4.5px solid ${theme.palette.customGrey.main}`,
      borderBottom: `4.5px solid ${theme.palette.customGrey.main}`,
      borderRadius: `0 0 32px 32px`,
      background: `linear-gradient(0deg, #6C98DA ${load}%, #F5F3F3 ${load}%)`,
      "&.error": {
        // borderColor: theme.palette.error.main,
        background: `linear-gradient(0deg, ${theme.palette.error.main} ${load}%, #F5F3F3 ${load}%)`,
      },
      "&.error1": {
        borderLeft: `4.5px solid ${theme.palette.error.main}`,
        borderRight: `4.5px solid ${theme.palette.error.main}`,
        borderBottom: `4.5px solid ${theme.palette.error.main}`,
      },
    };
  },
);

export const StyledBottomMsg = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    gap: 10,
    textTransform: "uppercase",
    color: theme.palette.error.main,
    "& > *:not(empty)": {
      paddingTop: theme.spacing(2),
    },
  };
});
