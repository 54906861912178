import React, { FC } from "react";
import { Stack, Skeleton, Grid } from "@mui/material";
import clsx from "clsx";

import {
  LockIcon,
  UnlockIcon,
  WaterContainerIcon,
  Divider,
} from "components/ui";
import { KnsErrorContainer } from "containers/knsError";

import { deviceWorkStatus } from "lib/enums";
import { Kns } from "lib/types";

import {
  StyledHeadInfo,
  StyledHeadItem,
  StyledHeadTypography,
  StyledInfoKns,
  StyledTitle,
} from "./styled";
import { useCheckKnsError } from "lib/hooks";

interface Props {
  kns: Kns;
}
export const KnsInfoContainer: FC<Props> = ({ kns }) => {
  const { isPitOverflow } = useCheckKnsError(kns);

  const skeleton = (value: any) => {
    return value ?? <Skeleton />;
  };

  return (
    <StyledInfoKns>
      <Stack direction="row" spacing={3}>
        <StyledTitle>
          {kns.name ? kns.name : `КНС №${kns.deviceCode}`}
        </StyledTitle>

        <StyledHeadItem>
          <StyledHeadTypography sx={{ maxWidth: 170 }} color="customGrey.main">
            {skeleton(kns.organizationUnit?.name)}
          </StyledHeadTypography>
          <StyledHeadTypography sx={{ maxWidth: 170 }}>
            {skeleton(kns.location?.shortAddress)}
          </StyledHeadTypography>
        </StyledHeadItem>

        <StyledHeadItem>
          <StyledHeadTypography color="customGrey.main" sx={{ minWidth: 109 }}>
            Код устройства
          </StyledHeadTypography>
          <StyledHeadTypography>
            {skeleton(kns.deviceCode)}
          </StyledHeadTypography>
        </StyledHeadItem>
      </Stack>

      <Grid container sx={{ mt: -2 }} spacing={3}>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1}>
            <StyledHeadInfo>
              {kns.reedSwitchInformation ? <UnlockIcon /> : <LockIcon />}
              <span className="label">Дверь</span>
            </StyledHeadInfo>
            <StyledHeadInfo
              sx={{ flexGrow: 1 }}
              className={clsx({
                error: isPitOverflow,
              })}>
              <WaterContainerIcon color={isPitOverflow ? "error" : "primary"} />
              <span className="label">Уровень</span>
            </StyledHeadInfo>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <StyledHeadInfo>
            {deviceWorkStatus[kns.workStatus ?? 0]}
          </StyledHeadInfo>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Grid container sx={{ mt: 0 }} spacing={3}>
        <KnsErrorContainer kns={kns} />
      </Grid>
    </StyledInfoKns>
  );
};
