import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const EngineVolumeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <mask id="path-1-inside-1_109_10220" fill="white">
        <path d="M1.91159 12.8C1.10328 11.5587 0.638949 10.1141 0.568495 8.62163C0.498041 7.12912 0.824126 5.64507 1.51171 4.32896C2.19929 3.01285 3.22238 1.91443 4.47103 1.15174C5.71967 0.389051 7.14667 -0.00907097 8.59867 0.000156843L16.5591 0.0200748V1.39052L8.59034 1.38887C7.38357 1.3812 6.19758 1.71208 5.15982 2.34596C4.12206 2.97983 3.27176 3.89274 2.70031 4.98657C2.12885 6.08041 1.85784 7.31381 1.91639 8.55424C1.97495 9.79468 2.36086 10.9952 3.03265 12.0269L1.91159 12.8Z" />
      </mask>
      <path
        d="M1.91159 12.8C1.10328 11.5587 0.638949 10.1141 0.568495 8.62163C0.498041 7.12912 0.824126 5.64507 1.51171 4.32896C2.19929 3.01285 3.22238 1.91443 4.47103 1.15174C5.71967 0.389051 7.14667 -0.00907097 8.59867 0.000156843L16.5591 0.0200748V1.39052L8.59034 1.38887C7.38357 1.3812 6.19758 1.71208 5.15982 2.34596C4.12206 2.97983 3.27176 3.89274 2.70031 4.98657C2.12885 6.08041 1.85784 7.31381 1.91639 8.55424C1.97495 9.79468 2.36086 10.9952 3.03265 12.0269L1.91159 12.8Z"
        fill="#E5F3FB"
      />
      <path
        d="M1.91159 12.8L0.654592 13.6185L1.4951 14.9093L2.76314 14.0349L1.91159 12.8ZM8.59867 0.000156843L8.58914 1.50014L8.59492 1.50015L8.59867 0.000156843ZM8.59034 1.38887L8.58081 2.88887L8.59003 2.88887L8.59034 1.38887ZM3.03265 12.0269L3.8842 13.2618L5.08674 12.4325L4.28965 11.2084L3.03265 12.0269ZM16.5591 1.39052L16.5588 2.89052L18.0591 2.89083V1.39052H16.5591ZM16.5591 0.0200748L18.0591 0.0200748V-1.47618L16.5628 -1.47992L16.5591 0.0200748ZM3.16859 11.9815C2.50706 10.9656 2.12483 9.77956 2.06683 8.5509L-0.929836 8.69236C-0.846927 10.4487 -0.300502 12.1518 0.654592 13.6185L3.16859 11.9815ZM2.06683 8.5509C2.00883 7.32221 2.27753 6.10247 2.84121 5.02354L0.182212 3.63439C-0.629281 5.18767 -1.01274 6.93604 -0.929836 8.69236L2.06683 8.5509ZM2.84121 5.02354C3.40473 3.94489 4.24016 3.05044 5.25292 2.43183L3.68913 -0.128354C2.2046 0.778416 0.993854 2.08081 0.182212 3.63439L2.84121 5.02354ZM5.25292 2.43183C6.26529 1.81347 7.41852 1.49269 8.58914 1.50013L8.60821 -1.49981C6.87482 -1.51083 5.17406 -1.03537 3.68913 -0.128354L5.25292 2.43183ZM8.59987 -0.111101C7.11172 -0.120558 5.65196 0.287665 4.37792 1.06587L5.94171 3.62605C6.74319 3.1365 7.65542 2.88296 8.58081 2.88884L8.59987 -0.111101ZM4.37792 1.06587C3.10428 1.84382 2.06632 2.9607 1.37081 4.292L4.0298 5.68115C4.4772 4.82478 5.13984 4.11585 5.94171 3.62605L4.37792 1.06587ZM1.37081 4.292C0.675443 5.62301 0.347052 7.12073 0.418061 8.62498L3.41472 8.48352C3.36862 7.50689 3.58226 6.53781 4.0298 5.68115L1.37081 4.292ZM0.418061 8.62498C0.489071 10.1293 0.957074 11.5883 1.77565 12.8454L4.28965 11.2084C3.76464 10.4022 3.46082 9.46011 3.41472 8.48352L0.418061 8.62498ZM2.1811 10.7921L1.06004 11.5651L2.76314 14.0349L3.8842 13.2618L2.1811 10.7921ZM16.5594 -0.109483L8.59065 -0.111131L8.59003 2.88887L16.5588 2.89052L16.5594 -0.109483ZM8.59492 1.50015L16.5553 1.52007L16.5628 -1.47992L8.60243 -1.49984L8.59492 1.50015ZM15.0591 0.0200748V1.39052H18.0591V0.0200748L15.0591 0.0200748Z"
        fill="#3C3939"
        mask="url(#path-1-inside-1_109_10220)"
      />
      <mask id="path-3-inside-2_109_10220" fill="white">
        <path d="M15.2066 3.2C16.0149 4.44133 16.4792 5.88587 16.5497 7.37837C16.6201 8.87088 16.294 10.3549 15.6065 11.671C14.9189 12.9872 13.8958 14.0856 12.6471 14.8483C11.3985 15.6109 9.97149 16.0091 8.51949 15.9998L0.55908 15.9799L0.55908 14.6095L8.52782 14.6111C9.73459 14.6188 10.9206 14.2879 11.9583 13.654C12.9961 13.0202 13.8464 12.1073 14.4179 11.0134C14.9893 9.91959 15.2603 8.68619 15.2018 7.44575C15.1432 6.20532 14.7573 5.00475 14.0855 3.97307L15.2066 3.2Z" />
      </mask>
      <path
        d="M15.2066 3.2C16.0149 4.44133 16.4792 5.88587 16.5497 7.37837C16.6201 8.87088 16.294 10.3549 15.6065 11.671C14.9189 12.9872 13.8958 14.0856 12.6471 14.8483C11.3985 15.6109 9.97149 16.0091 8.51949 15.9998L0.55908 15.9799L0.55908 14.6095L8.52782 14.6111C9.73459 14.6188 10.9206 14.2879 11.9583 13.654C12.9961 13.0202 13.8464 12.1073 14.4179 11.0134C14.9893 9.91959 15.2603 8.68619 15.2018 7.44575C15.1432 6.20532 14.7573 5.00475 14.0855 3.97307L15.2066 3.2Z"
        fill="#E5F3FB"
      />
      <path
        d="M15.2066 3.2L16.4636 2.38149L15.6231 1.09072L14.355 1.96514L15.2066 3.2ZM8.51949 15.9998L8.52902 14.4999L8.52324 14.4998L8.51949 15.9998ZM8.52782 14.6111L8.53736 13.1111L8.52813 13.1111L8.52782 14.6111ZM14.0855 3.97307L13.234 2.73822L12.0314 3.56748L12.8285 4.79158L14.0855 3.97307ZM0.55908 14.6095L0.55939 13.1095L-0.94092 13.1092L-0.94092 14.6095L0.55908 14.6095ZM0.55908 15.9799L-0.94092 15.9799L-0.94092 17.4762L0.555326 17.4799L0.55908 15.9799ZM13.9496 4.01851C14.6111 5.03442 14.9933 6.22044 15.0513 7.4491L18.048 7.30764C17.9651 5.55129 17.4187 3.84824 16.4636 2.38149L13.9496 4.01851ZM15.0513 7.4491C15.1093 8.67779 14.8406 9.89753 14.277 10.9765L16.936 12.3656C17.7474 10.8123 18.1309 9.06396 18.048 7.30764L15.0513 7.4491ZM14.277 10.9765C13.7134 12.0551 12.878 12.9496 11.8652 13.5682L13.429 16.1284C14.9136 15.2216 16.1243 13.9192 16.936 12.3656L14.277 10.9765ZM11.8652 13.5682C10.8529 14.1865 9.69964 14.5073 8.52902 14.4999L8.50996 17.4998C10.2433 17.5108 11.9441 17.0354 13.429 16.1284L11.8652 13.5682ZM8.51829 16.1111C10.0064 16.1206 11.4662 15.7123 12.7402 14.9341L11.1764 12.3739C10.375 12.8635 9.46274 13.117 8.53736 13.1112L8.51829 16.1111ZM12.7402 14.9341C14.0139 14.1562 15.0518 13.0393 15.7474 11.708L13.0884 10.3188C12.641 11.1752 11.9783 11.8842 11.1764 12.3739L12.7402 14.9341ZM15.7474 11.708C16.4427 10.377 16.7711 8.87927 16.7001 7.37502L13.7034 7.51648C13.7495 8.49311 13.5359 9.46219 13.0884 10.3188L15.7474 11.708ZM16.7001 7.37502C16.6291 5.87075 16.1611 4.41167 15.3425 3.15456L12.8285 4.79158C13.3535 5.59784 13.6573 6.53989 13.7034 7.51648L16.7001 7.37502ZM14.9371 5.20793L16.0581 4.43486L14.355 1.96514L13.234 2.73822L14.9371 5.20793ZM0.558769 16.1095L8.52751 16.1111L8.52813 13.1111L0.55939 13.1095L0.558769 16.1095ZM8.52324 14.4998L0.562834 14.4799L0.555326 17.4799L8.51574 17.4998L8.52324 14.4998ZM2.05908 15.9799L2.05908 14.6095L-0.94092 14.6095L-0.94092 15.9799L2.05908 15.9799Z"
        fill="#3C3939"
        mask="url(#path-3-inside-2_109_10220)"
      />
      <line
        y1="-0.75"
        x2="10.8954"
        y2="-0.75"
        transform="matrix(-0.476011 0.87944 -0.851485 -0.524379 10.4985 3.11179)"
        stroke="#3C3939"
        strokeWidth="1.5"
      />
      <ellipse
        rx="1.93234"
        ry="2.06107"
        transform="matrix(0.999794 -0.0202838 0.0178284 0.999841 8.57758 7.86991)"
        fill="#3B3939"
      />
    </SvgIcon>
  );
};
