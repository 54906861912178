import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { ReasonType } from "../../lib/enums";

type Props = SelectProps & {
  helperText: any;
};
export const ReasonTypeSelect = (props: Props) => {
  return (
    <>
      <FormControl fullWidth size="small" error={Boolean(props.helperText)}>
        <InputLabel id="access-select">Причина</InputLabel>
        <Select
          labelId="access-select"
          id="access-select"
          label="Причина"
          {...props}>
          {Object.keys(ReasonType).map((key) => {
            return (
              <MenuItem value={key}>
                {/* @ts-ignore */}
                {ReasonType[key]}
              </MenuItem>
            );
          })}
        </Select>
        {props.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};
