import { makeAutoObservable } from "mobx";

class Situation {
  // выбранная ситуация
  public activeSituationId: number | null = null;
  // перезапросить список в SituationListContainer
  public refetchSituationListContainer: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }
}

const situation = new Situation();

export default situation;
