import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const StatusWorkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <mask id="path-1-inside-1_109_10212" fill="white">
        <path d="M12 1.0718C13.5251 1.95233 14.7171 3.31151 15.391 4.93853C16.065 6.56555 16.1832 8.36948 15.7274 10.0706C15.2716 11.7716 14.2672 13.2748 12.8701 14.3468C11.4729 15.4189 9.76108 16 8 16C6.23893 16 4.52706 15.4189 3.12991 14.3468C1.73276 13.2748 0.728393 11.7716 0.272594 10.0706C-0.183206 8.36949 -0.0649703 6.56555 0.608963 4.93853C1.2829 3.31151 2.47486 1.95233 4 1.0718L4.85496 2.55263C3.65581 3.24497 2.71861 4.31363 2.18873 5.59289C1.65884 6.87215 1.56588 8.29051 1.92425 9.62799C2.28263 10.9655 3.07232 12.1473 4.17085 12.9903C5.26937 13.8332 6.61534 14.2901 8 14.2901C9.38466 14.2901 10.7306 13.8332 11.8292 12.9903C12.9277 12.1473 13.7174 10.9655 14.0757 9.62799C14.4341 8.29051 14.3412 6.87215 13.8113 5.59289C13.2814 4.31363 12.3442 3.24496 11.145 2.55263L12 1.0718Z" />
      </mask>
      <path
        d="M12 1.0718C13.5251 1.95233 14.7171 3.31151 15.391 4.93853C16.065 6.56555 16.1832 8.36948 15.7274 10.0706C15.2716 11.7716 14.2672 13.2748 12.8701 14.3468C11.4729 15.4189 9.76108 16 8 16C6.23893 16 4.52706 15.4189 3.12991 14.3468C1.73276 13.2748 0.728393 11.7716 0.272594 10.0706C-0.183206 8.36949 -0.0649703 6.56555 0.608963 4.93853C1.2829 3.31151 2.47486 1.95233 4 1.0718L4.85496 2.55263C3.65581 3.24497 2.71861 4.31363 2.18873 5.59289C1.65884 6.87215 1.56588 8.29051 1.92425 9.62799C2.28263 10.9655 3.07232 12.1473 4.17085 12.9903C5.26937 13.8332 6.61534 14.2901 8 14.2901C9.38466 14.2901 10.7306 13.8332 11.8292 12.9903C12.9277 12.1473 13.7174 10.9655 14.0757 9.62799C14.4341 8.29051 14.3412 6.87215 13.8113 5.59289C13.2814 4.31363 12.3442 3.24496 11.145 2.55263L12 1.0718Z"
        fill="#E5F3FB"
        stroke="#3C3939"
        strokeWidth="2"
        mask="url(#path-1-inside-1_109_10212)"
      />
      <line
        x1="8.21692"
        y1="-2.98311e-08"
        x2="8.21692"
        y2="10.6667"
        stroke="#3C3939"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
