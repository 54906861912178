import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

type Props = {
  type?: string;
  className?: string;
} & SvgIconProps;
export const EngineIcon: FC<Props> = ({ type, className, style, ...props }) => {
  let stroke = "#CCD2E3";

  switch (type) {
    case "error":
      stroke = "#EB4343";
      break;
    default:
      stroke = "#CCD2E3";
  }

  return (
    <SvgIcon
      viewBox="0 0 14 18"
      style={{ width: 14, height: 14, fill: "none", ...style }}
      {...props}>
      <path
        d="M0.92577 8.71228C0.983532 9.89584 1.32418 11.0459 1.91617 12.0681L1.57002 12.299C0.935562 11.2137 0.570717 9.99065 0.509289 8.73197C0.439764 7.30736 0.761535 5.89071 1.44019 4.63422C2.11886 3.3777 3.12884 2.32871 4.36188 1.60022C5.59493 0.871711 7.00429 0.491334 8.4385 0.500151L8.43975 0.500156L13.5 0.518572V0.912094L8.43589 0.910579C8.4354 0.910576 8.4349 0.910573 8.43441 0.91057C7.07741 0.902497 5.74369 1.2625 4.57644 1.95213C3.40874 2.64202 2.45168 3.63579 1.80835 4.82687C1.16502 6.01798 0.859836 7.36124 0.92577 8.71228Z"
        stroke={stroke}
      />
      <path
        d="M13.0742 7.28772C13.0165 6.10416 12.6758 4.95407 12.0838 3.93189L12.43 3.701C13.0644 4.78627 13.4293 6.00935 13.4907 7.26803C13.5602 8.69264 13.2385 10.1093 12.5598 11.3658C11.8811 12.6223 10.8712 13.6713 9.63812 14.3998C8.40507 15.1283 6.9957 15.5087 5.5615 15.4998L5.56025 15.4998L0.499999 15.4814L0.499999 15.0879L5.5641 15.0894C5.5646 15.0894 5.56509 15.0894 5.56559 15.0894C6.92259 15.0975 8.25631 14.7375 9.42356 14.0479C10.5913 13.358 11.5483 12.3642 12.1916 11.1731C12.835 9.98202 13.1402 8.63876 13.0742 7.28772Z"
        stroke={stroke}
      />
      <path
        d="M2.90576 7.26645L6.00012 8.5M11.623 8.71704L8.52869 7.5"
        stroke={stroke}
      />
      <circle
        cx="7.28429"
        cy="8.03936"
        r="1.60547"
        transform="rotate(-1.08963 7.28429 8.03936)"
        stroke={stroke}
      />
    </SvgIcon>
  );
};
