import { toast } from "react-toastify";
import { dispatcherDuck } from "lib/ducks";
import { SSE_URL } from "lib/config";

class Sse {
  private eventSource: EventSource | null = null;

  initSse() {
    console.log("initSse", new Date().toLocaleTimeString());
    this.eventSource?.close();
    try {
      this.eventSource = new EventSource(SSE_URL);

      this.eventSource.onmessage = (event) => {
        switch (event.lastEventId) {
          case "LogInfo":
            console.log(event);
            toast.error("Не корректные данные по КНС");
            break;
        }
      };

      this.eventSource.onerror = (e) => {
        console.error(`onError sse ${new Date().toLocaleTimeString()}`, e);
      };
    } catch (error) {
      console.log(`try catch ${new Date().toLocaleTimeString()}`);
    }
  }

  closeSse() {
    this.eventSource?.close();
  }
}

const sse = new Sse();

export default sse;
