import { checkDate, checkDateR } from "lib/helpers";
import { Kns } from "lib/types";
import React, { useState } from "react";
import { FC } from "react";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

const AlarmBox = styled("div")`
  box-sizing: border-box;
  position: absolute;
  width: 500px;
  border: 2px solid #ff0000;
  background-color: #ffffff;
  z-index: 1200;
  top: 8px;
  right: 8px;
  padding: 8px 12px;
  border-radius: 12px;
`;

const AlarmList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
  margin-top: 5px;
`;

const AlarmItem = styled("div")`
  display: inline-block;
  min-width: 150px;
`;

const Button = styled("div")`
  box-sizing: border-box;
  position: absolute;
  border: 2px solid #ff0000;
  background-color: #ffffff;
  z-index: 1200;
  top: 8px;
  right: 8px;
  padding: 8px 12px;
  border-radius: 12px;
  cursor: pointer;
`;

const CloseBox = styled("div")`
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.6;
  cursor: pointer;
`;

export const KnsTimeAlarm: FC<{ list: Kns[] }> = ({ list = [] }) => {
  const filtred = list.filter((kns) => checkDateR(kns.updated));
  const [open, setOpen] = useState(true);

  if (filtred.length) {
    if (open) {
      return (
        <AlarmBox>
          <CloseBox onClick={() => setOpen(false)}>
            <Close />
          </CloseBox>
          <div>
            <b>Более 30 мин нет ответа от</b> (последнее обновление):
          </div>
          <AlarmList>
            {filtred.map((item) => (
              <AlarmItem key={item.deviceCode}>
                {item.name ? item.name : `КНС №${item.deviceCode}`} (
                {checkDate(item.updated)}),
              </AlarmItem>
            ))}
          </AlarmList>
        </AlarmBox>
      );
    }
    return <Button onClick={() => setOpen(true)}>T!</Button>;
  }
  return null;
};
