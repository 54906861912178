import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";

import { useJournals } from "lib/hooks";
import { Journal, Kns } from "lib/types";

import {
  StyledDivider,
  ModalForm,
  Table,
  StyledHeadTypography,
  StyledTitle,
  StyledWorkStatus,
} from "./components";
import { deviceWorkStatus } from "lib/enums";

interface Props {
  kns?: Kns;
  maxHeight?: string;
  title?: ReactNode;
}
export const JournalContainer: FC<Props> = observer(
  ({ kns, maxHeight, title }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [editJournal, setEditJournal] = useState<Journal | null>(null);
    const [filter, setFilter] = useState({});
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    // показывать или нет в форме выбор кнс
    const [hideKnsSelect, setHideKnsSelect] = useState<boolean>(false);

    const {
      isLoading,
      data: journals,
      refetch,
    } = useJournals(page, pageSize, filter);

    const handleEdit = (journal: Journal) => () => {
      setEditJournal(journal);
      handleOpenModal();
    };

    const handleCloseModal = () => {
      setOpen(false);
      setEditJournal(null);
    };

    const handleOpenModal = () => {
      setOpen(true);
    };

    const handleOpenAdd = () => {
      if (kns) {
        setEditJournal({
          date: null,
          type: kns.type,
          knsId: kns.id,
          objectId: kns.id,
        } as Journal);
      }
      handleOpenModal();
    };

    const hanleChangePage = (page: number) => {
      setPage(page);
    };

    const hanleChangePerPage = (pageSize: number) => {
      setPageSize(pageSize);
    };

    useEffect(() => {
      if (kns) {
        setFilter({
          type: kns.type,
          objectId: kns.id,
        });
        setHideKnsSelect(true);
      }
    }, [kns]);

    return (
      <>
        <ModalForm
          initValue={editJournal}
          open={open}
          onClose={handleCloseModal}
          refetch={refetch}
          hideKnsSelect={hideKnsSelect}
        />
        <Grid item xs={12}>
          <Stack direction="row" spacing={3} alignItems="center">
            {title ? (
              <>{title}</>
            ) : (
              kns && (
                <>
                  <StyledTitle>{kns?.name}</StyledTitle>
                  <div>
                    <StyledHeadTypography color="customGrey.main">
                      {kns?.organizationUnit?.name ?? "-"}
                    </StyledHeadTypography>
                    <StyledHeadTypography>
                      {kns?.location?.shortAddress}
                    </StyledHeadTypography>
                  </div>
                  <StyledWorkStatus>
                    <StyledHeadTypography className="active">
                      {kns?.workStatus !== undefined
                        ? deviceWorkStatus[kns?.workStatus]
                        : "-"}
                    </StyledHeadTypography>
                  </StyledWorkStatus>
                </>
              )
            )}
            <div style={{ flexGrow: 1 }} />
            <Button onClick={handleOpenAdd} variant="outlined" size="small">
              + Добавить запись
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} sx={{ mt: 2 }}>
          <StyledDivider />
        </Grid>
        <Grid item xs={12}>
          <Table
            page={page}
            pageSize={pageSize}
            journals={journals}
            isLoading={isLoading}
            handleEdit={handleEdit}
            onChangePage={hanleChangePage}
            onChangePerPage={hanleChangePerPage}
            maxHeight={maxHeight}
          />
        </Grid>
      </>
    );
  },
);
