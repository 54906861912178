import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link } from "react-router-dom";

export const MenuIconContainer = styled("div")`
  padding: 8px 16px 0px 16px;
`;
export const MenuFoldOutlinedStyled = styled(Menu)(({ theme }) => {
  return {
    cursor: "pointer",
    marginRight: "8px 16px 8px 0px",
    color: theme.palette.primary.main,
  };
});

export const ChildrenContainer = styled(Grid)(({ theme }) => {
  return {
    flexDirection: "column",
    zIndex: theme.zIndex.appBar,
    flexGrow: "1 !important",
  };
});

export const HeaderLink = styled(Link)(({ theme }) => {
  return {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    color: theme.palette.black.main,
    textDecoration: "none",
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(2),
    display: "block",
    "&.active": {
      background: theme.palette.primary.light,
      borderRadius: theme.spacing(1),
    },
  };
});

export const MainContainer = styled(Grid)(({ theme }) => {
  return {
    minWidth: 1440,
    minHeight: "100%",
    flexDirection: "column",
  };
});

export const Header = styled(Grid)(({ theme }) => {
  return {
    width: 558,
    marginBlock: theme.spacing(1),
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    background: theme.palette.background.paper,
    borderRadius: `0px ${theme.spacing(1)} ${theme.spacing(1)} 0px`,
    zIndex: theme.zIndex.appBar,
  };
});
