import { Button, Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    position: "absolute" as "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    width: 700,
    borderRadius: theme.shape.borderRadius,
  };
});

export const StyledDivContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "calc(100vh - 442px)",
    marginInline: theme.spacing(-2),
    paddingInline: theme.spacing(2),
  };
});

export const StyledJournalTitle = styled("span")(({ theme }) => {
  return {
    fontSize: 14,
    background: theme.palette.primary.light,
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  };
});

export const StyledBtn = styled(Button)<{ underline?: boolean }>(
  ({ theme, underline }) => {
    return {
      textTransform: "none",
      textDecoration: underline ? "underline" : "none",
    };
  },
);

export const StyledStackItem = styled("div")<{
  grey?: boolean;
}>(({ theme, grey }) => {
  return {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    color: grey ? theme.palette.customGrey.main : theme.palette.black.dark,
  };
});

export const StyledBorderBottom = styled(Grid)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    paddingBottom: theme.spacing(2),
  };
});

export const StyledBorderBottomRight = styled(StyledBorderBottom)(
  ({ theme }) => {
    return {
      borderRight: `1px solid ${theme.palette.primary.light}`,
    };
  },
);
