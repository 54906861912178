import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ErrorNotifIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <path
        d="M8.32884 1.07172L8.48706 1.80484H8.48706L8.32884 1.07172ZM9.67116 1.07172L9.82938 0.338595V0.338595L9.67116 1.07172ZM6.00841 2.89952L5.34159 2.55623V2.55623L6.00841 2.89952ZM1.40494 11.8413L2.07175 12.1846H2.07175L1.40494 11.8413ZM16.5951 11.8413L15.9282 12.1846H15.9282L16.5951 11.8413ZM11.9916 2.89952L11.3248 3.24282L11.9916 2.89952ZM8.48706 1.80484C8.82583 1.73172 9.17417 1.73172 9.51294 1.80484L9.82938 0.338595C9.28205 0.220468 8.71795 0.220468 8.17062 0.338595L8.48706 1.80484ZM6.67523 3.24282C7.0669 2.48202 7.73599 1.96693 8.48706 1.80484L8.17062 0.338595C6.95272 0.601446 5.92506 1.42288 5.34159 2.55623L6.67523 3.24282ZM2.07175 12.1846L6.67523 3.24282L5.34159 2.55623L0.738117 11.498L2.07175 12.1846ZM1.75 13.5276C1.75 13.0548 1.86181 12.5924 2.07175 12.1846L0.738117 11.498C0.416919 12.1219 0.25 12.8202 0.25 13.5276H1.75ZM4.21779 16.25C2.90762 16.25 1.75 15.086 1.75 13.5276H0.25C0.25 15.8048 1.97369 17.75 4.21779 17.75V16.25ZM13.7822 16.25H4.21779V17.75H13.7822V16.25ZM16.25 13.5276C16.25 15.086 15.0924 16.25 13.7822 16.25V17.75C16.0263 17.75 17.75 15.8048 17.75 13.5276H16.25ZM15.9282 12.1846C16.1382 12.5924 16.25 13.0548 16.25 13.5276H17.75C17.75 12.8202 17.5831 12.1219 17.2619 11.498L15.9282 12.1846ZM11.3248 3.24282L15.9282 12.1846L17.2619 11.498L12.6584 2.55623L11.3248 3.24282ZM9.51294 1.80484C10.264 1.96693 10.9331 2.48202 11.3248 3.24282L12.6584 2.55623C12.0749 1.42288 11.0473 0.601446 9.82938 0.338595L9.51294 1.80484Z"
        fill="#3B3939"
      />
      <path
        d="M9 6.3588L9 9.57104"
        stroke="#3B3939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.9805L9 12.3821"
        stroke="#3B3939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
