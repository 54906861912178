export enum RoutesEnum {
  JOURNAL = "dispatcher-journal",
  KNS = "",
  VNS = "dispatcher-vns",
  MONITORING = "dispatcher-monitoring",
  SITUATION = "dispatcher-situation",
}

export const RoutesType: Record<RoutesEnum, string> = {
  [RoutesEnum.JOURNAL]: "Журналы",
  [RoutesEnum.KNS]: "Объекты КНС",
  [RoutesEnum.VNS]: "Объекты ВНС",
  [RoutesEnum.MONITORING]: "Мониторинг",
  [RoutesEnum.SITUATION]: "Ситуации",
};
