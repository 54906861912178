import { FacilityTypeEnum, pumpStatuseEnum } from "./enums";
import { Kns } from "./types";
import { format } from "date-fns";
import React from "react";

export const localeDate = (
  date: string | number | Date,
  locale: string = "ru-Ru",
  option?: any,
) => {
  return new Date(date).toLocaleDateString(locale, option);
};

export const localeTime = (
  date: string | number | Date,
  locale: string = "ru-Ru",
  option: any = {
    hour: "2-digit",
    minute: "2-digit",
  },
) => {
  return new Date(date).toLocaleTimeString(locale, option);
};

export const someIncludes = (someArr: any[], includesArr: any[]) => {
  return someArr?.some((access) => includesArr.includes(access)) ?? false;
};

export const calcHeight = (type: FacilityTypeEnum, showList: boolean) => {
  if (!showList) {
    return "calc(100vh - 270px)";
  }
  return type === FacilityTypeEnum.KNS
    ? "calc(100vh - 535px)"
    : "calc(100vh - 270px)";
};

export const getLiquidLevel = (kns: Kns, isUpperSensorError: boolean) => {
  if (kns.deviceCode === 10 || kns.deviceCode === 11) {
    if (isUpperSensorError) {
      return 120;
    }

    if (kns.middleBeacon) {
      return 75;
    }

    if (kns.lowBeacon) {
      return 25;
    }
  }

  return kns?.liquidLevel ?? 0;
};

export const checkDate = (updated: string | Date) => {
  const updatedDate = new Date(updated);
  const currentDate = Date.now();

  const deltaDateMin = (currentDate - updatedDate.getTime()) / 60000;
  const isProblem = deltaDateMin > 30;

  return (
    <span style={{ color: isProblem ? "#ff0000" : "#000000" }}>
      {format(updatedDate, "HH:mm:ss")}
    </span>
  );
};

export const checkDateR = (updated: string | Date) => {
  const updatedDate = new Date(updated);
  const currentDate = Date.now();

  const deltaDateMin = (currentDate - updatedDate.getTime()) / 60000;
  const isProblem = deltaDateMin > 30;

  return isProblem;
};
