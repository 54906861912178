import { styled } from "@mui/material/styles";
import { Grid, Typography, Divider, Box } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[24],
    padding: theme.spacing(2),
  };
});

export const StyledDivider = styled(Divider)(({ theme }) => {
  return {
    borderColor: theme.palette.primary.light,
  };
});

export const StyledHeadTypography = styled(Typography)(({ theme }) => {
  return {
    fontSize: 14,
    "&.active": {
      background: theme.palette.primary.light,
      paddingInline: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
    },
  };
});

export const StyledTitle = styled(Typography)(({ theme }) => {
  return {
    lineHeight: theme.spacing(4),
    fontSize: theme.spacing(4),
    fontWeight: 500,
  };
});

export const StyledWorkStatus = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
  };
});

export const StyledDate = styled("span")(({ theme }) => {
  return {
    color: theme.palette.customGrey.main,
  };
});
