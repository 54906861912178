import React, { FC, useState } from "react";
import { IconButton } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";

import { StyledTable } from "components/ui/Table";
import { ListResponse } from "lib/api";
import { ReasonTypeEnum, ReasonType } from "lib/enums";
import { localeTime, localeDate } from "lib/helpers";
import { Journal, Kns, Vns, JournalVehicle, Employee, User } from "lib/types";

import { StyledDate } from "./styled";

interface Props {
  isLoading: boolean;
  journals?: ListResponse<Journal>;
  handleEdit: (journal: Journal) => () => void;
  onChangePage: (page: number) => void;
  onChangePerPage: (pageSize: number) => void;
  page: number;
  pageSize: number;
  withOutQueryParams?: boolean;
  maxHeight?: string;
}
export const Table: FC<Props> = ({
  isLoading,
  journals,
  handleEdit,
  page,
  pageSize,
  onChangePerPage,
  onChangePage,
  withOutQueryParams = false,
  maxHeight,
}) => {
  const columns = [
    {
      title: "Время заявки",
      width: 100,
      field: "date",
      render: (date: string, row: Journal) => {
        return (
          <span key={`date-${row?.id}`}>
            {localeTime(date)} <StyledDate>{localeDate(date)}</StyledDate>
          </span>
        );
      },
    },
    {
      title: "Адрес",
      field: "object",
      render: (object: Kns | Vns) => {
        return object?.location?.shortAddress ?? "-";
      },
    },
    {
      title: "Выполнена",
      width: 100,
      field: "vehicles",
      render: (vehicles: JournalVehicle[]) => {
        return (
          vehicles?.map((vehicle, index: number) => {
            return (
              <div key={`${vehicle?.id}-${index}`}>
                {localeTime(vehicle.startDate ?? "")} -{" "}
                {localeTime(vehicle.endDate ?? "")}
              </div>
            );
          }) ?? "-"
        );
      },
    },
    {
      title: "Транспорт",
      field: "vehicles",
      render: (vehicles: JournalVehicle[]) => {
        return (
          vehicles?.map((vehicle, index) => {
            return (
              <div key={`index-${vehicle?.id}`}>{vehicle.licensePlate}</div>
            );
          }) ?? "-"
        );
      },
    },
    {
      title: "Причина",
      field: "reason",
      render: (reason: ReasonTypeEnum, row: Journal) => {
        return ReasonType[reason];
      },
    },
    { title: "Меры устранения", field: "solution" },
    {
      title: "Исполнитель",
      field: "employee",
      render: (employee: Employee) => {
        return (
          employee &&
          `${employee?.firstName} ${employee?.middleName} ${employee?.lastName}`
        );
      },
    },
    {
      title: "Диспетчер",
      field: "user",
      render: (user: User) => {
        return user?.employee
          ? `${user.employee.firstName} ${user.employee.middleName} ${user.employee.lastName}`
          : user?.userName ?? "-";
      },
    },
    {
      title: "",
      field: "id",
      render: (_id: number, journal: Journal) => {
        return (
          <IconButton color="primary" onClick={handleEdit(journal)}>
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  const hanleChangePage = (page: number) => {
    onChangePage(page);
  };

  const hanleChangePerPage = (pageSize: number) => {
    onChangePerPage(pageSize);
  };

  return (
    <StyledTable
      key="journal-table"
      tableContainerSx={{
        minHeight: "100px",
        maxHeight: maxHeight ? maxHeight : "calc(100vh - 300px)",
      }}
      isLoading={isLoading}
      columns={columns}
      data={journals?.data ?? []}
      withOutQueryParams={withOutQueryParams}
      pagination={{
        pageSize,
        page,
        totalCount: journals?.totalCount ?? 0,
        hanleChangePage,
        hanleChangePerPage,
      }}
    />
  );
};
