import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

type Props = {
  type?: string;
  className?: string;
} & SvgIconProps;
export const ElectrIcon: FC<Props> = ({ type, className, style, ...props }) => {
  let stroke = "none";
  switch (type) {
    case "error":
      stroke = "#EB4343";
      break;
    case "primary":
      stroke = "#6C98DA";
      break;
    default:
      stroke = "#CCD2E3";
  }
  return (
    <SvgIcon
      className={className}
      viewBox="0 0 11 18"
      fill="none"
      style={{ width: 11, height: 18, fill: "none", ...style }}
      {...props}>
      <path
        d="M4.14632 11.1H5V14.7553L5 14.7875C4.99998 15.201 4.99997 15.5536 5.02736 15.8066C5.04116 15.9341 5.06603 16.0842 5.12644 16.2187C5.19208 16.3648 5.33027 16.5525 5.58693 16.6097C5.84361 16.6669 6.04841 16.5555 6.16986 16.4511C6.28165 16.355 6.36785 16.2297 6.43446 16.1201C6.56667 15.9026 6.71631 15.5833 6.8918 15.2089L6.90546 15.1797L9.11732 10.4611L9.13463 10.4242C9.42017 9.81506 9.65837 9.30696 9.78236 8.89307C9.91124 8.46283 9.95609 8.00962 9.69273 7.59532C9.42938 7.18102 8.99998 7.02927 8.55571 6.96336C8.12833 6.89995 7.56716 6.89997 6.89445 6.9L6.85368 6.9H6V3.24471L6 3.21255C6.00002 2.79902 6.00003 2.44641 5.97264 2.19335C5.95884 2.06586 5.93397 1.91581 5.87355 1.78131C5.80792 1.6352 5.66973 1.44747 5.41306 1.39029C5.15639 1.33312 4.95159 1.44446 4.83014 1.5489C4.71835 1.64504 4.63215 1.77035 4.56554 1.87993C4.43333 2.09743 4.28369 2.41671 4.10819 2.79114L4.09454 2.82027L1.88268 7.53891L1.86537 7.57583C1.57983 8.18493 1.34163 8.69304 1.21764 9.10693C1.08876 9.53717 1.04391 9.99038 1.30727 10.4047C1.57062 10.819 2.00002 10.9707 2.44429 11.0366C2.87167 11.1 3.43284 11.1 4.10555 11.1L4.14632 11.1Z"
        stroke={stroke}
      />
    </SvgIcon>
  );
};
