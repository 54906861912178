import React from "react";

export default () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50337 0.0537867C6.17108 -0.0179289 5.82892 -0.0179289 5.49663 0.0537867C4.75826 0.213143 4.12199 0.714338 3.7563 1.42464L0.303702 8.13099C0.104523 8.51787 0 8.95315 0 9.39573C0 10.834 1.08049 12 2.41334 12H9.58666C10.9195 12 12 10.834 12 9.39573C12 8.95315 11.8955 8.51787 11.6963 8.13099L8.2437 1.42464C7.87801 0.714338 7.24174 0.213143 6.50337 0.0537867ZM6 7.17822C5.58579 7.17822 5.25 6.84244 5.25 6.42822V4.01904C5.25 3.60483 5.58579 3.26904 6 3.26904C6.41421 3.26904 6.75 3.60483 6.75 4.01904V6.42822C6.75 6.84244 6.41421 7.17822 6 7.17822ZM6.75 8.53676C6.75 8.95097 6.41421 9.28676 6 9.28676C5.58579 9.28676 5.25 8.95097 5.25 8.53676V8.23561C5.25 7.8214 5.58579 7.48561 6 7.48561C6.41421 7.48561 6.75 7.8214 6.75 8.23561V8.53676Z"
        fill="#EB4343"
      />
    </svg>
  );
};
