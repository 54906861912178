import { useMutation } from "react-query";
import { journalApi } from "../../api";
import { Journal } from "../../types";

const useAddUpdate = () => {
  const mutation = useMutation((journal: Journal): Promise<Journal> => {
    return journal?.id
      ? journalApi.updatePut({ ...journal, reason: String(journal.reason) })
      : journalApi.add(journal);
  });
  return mutation;
};

export default useAddUpdate;
