import { Kns, KnsMessage } from "../types";
import { CommonApi, CommonApiMethod } from ".";
import { deviceWorkStatusEnum, DeviceEmergencyCodeEnum } from "lib/enums";

const MOCK_DATA: KnsMessage = {
  workStatus: deviceWorkStatusEnum.auto,
  emergencyCode: [DeviceEmergencyCodeEnum.nonEmergency],
  liquidLevel: 0,
  reedSwitchInformation: false,
  // pumpsCount: 0,
  pumpStatuses: [],
  pumpEfficiency: 0,
} as any as KnsMessage;

const convertDataFromServer = (kns: Kns): Kns => {
  let knsMessage: KnsMessage = MOCK_DATA;
  if (Array.isArray(kns.knsMessages) && kns.knsMessages.length !== 0) {
    if (kns.knsMessages[0]) {
      const { pumpsCount, ...message } = kns.knsMessages[0];
      knsMessage = { ...message } as KnsMessage;
    }
  }
  return {
    ...kns,
    ...knsMessage,
  };
};

const route = "kns";
const kns: CommonApiMethod = new CommonApi<Kns>(
  route,
  "КНС",
  undefined,
  convertDataFromServer,
);

export default kns;
