import React, { FC, useState } from "react";
import { Grid, IconButton, Modal, Stack, Typography } from "@mui/material";
import { StyledBox, StyledDivider } from "./styled";
import { Close } from "@mui/icons-material";
import { JournalForm } from "components/forms";
import { Journal } from "lib/types";
import {
  useAddUpdateJournal,
  useAddUpdateJournalVehicle,
  useDeleteJournalVehicle,
} from "lib/hooks";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  initValue?: Journal | null;
  hideKnsSelect: boolean;
}
export const ModalForm: FC<Props> = ({
  open,
  onClose,
  initValue,
  refetch,
  hideKnsSelect,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const mutationJournal = useAddUpdateJournal();
  const mutationJournalVehicle = useAddUpdateJournalVehicle();
  const deleteJournalVehicle = useDeleteJournalVehicle();

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async (
    journal: Journal,
    deleteJournalVehicleIndexes: number[],
  ) => {
    try {
      setLoading(true);
      let promises: any[] = [];
      let journalId: number = 0;
      if (journal?.id) {
        promises.push(mutationJournal.mutateAsync(journal));
        deleteJournalVehicleIndexes.forEach((id) => {
          promises.push(deleteJournalVehicle.mutateAsync(Number(id)));
        });
        journalId = journal.id;
      } else {
        const res = await mutationJournal.mutateAsync(journal);
        journalId = (res as Journal)?.id ?? 0;
      }
      journal.vehicles.forEach((veh) => {
        veh.licensePlate &&
          promises.push(
            mutationJournalVehicle.mutateAsync({
              ...veh,
              journalId,
            }),
          );
      });
      await Promise.all(promises);
      refetch();
      handleCancel();
    } catch (error) {
      toast.error(`Ошибка: Не удалось корректно сохранить запись на сервере`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <StyledBox>
        <Stack direction="row">
          <div style={{ flexGrow: 1 }}>
            <Typography variant="h6">
              {initValue?.id ? "Редактирование" : "Добавление"} записи журнала
            </Typography>
          </div>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Stack>
        <Grid container>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <StyledDivider />
          </Grid>
          <Grid item xs={12}>
            <JournalForm
              isLoading={isLoading}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              initialValues={initValue}
              hiddenKnsVnsSelect={hideKnsSelect}
            />
          </Grid>
        </Grid>
      </StyledBox>
    </Modal>
  );
};
