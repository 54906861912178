import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ConnectionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <mask id="path-1-inside-1_109_10238" fill="white">
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.35113 8C1.35113 11.6721 4.32793 14.6489 8 14.6489C11.6721 14.6489 14.6489 11.6721 14.6489 8C14.6489 4.32793 11.6721 1.35113 8 1.35113C4.32793 1.35113 1.35113 4.32793 1.35113 8Z" />
      </mask>
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.35113 8C1.35113 11.6721 4.32793 14.6489 8 14.6489C11.6721 14.6489 14.6489 11.6721 14.6489 8C14.6489 4.32793 11.6721 1.35113 8 1.35113C4.32793 1.35113 1.35113 4.32793 1.35113 8Z"
        fill="#E5F3FB"
        stroke="#3C3939"
        strokeWidth="3"
        mask="url(#path-1-inside-1_109_10238)"
      />
      <path
        d="M6.70863 1.02832L5.67481 3.1528C4.05785 6.47564 4.2174 10.3884 6.09953 13.5685L7.19832 15.4251"
        stroke="#3C3939"
        strokeWidth="1.5"
      />
      <path
        d="M8.71667 1.02832L9.75048 3.1528C11.3674 6.47564 11.2079 10.3884 9.32576 13.5685L8.22697 15.4251"
        stroke="#3C3939"
        strokeWidth="1.5"
      />
      <path
        d="M1.02832 7.7124V7.7124C5.68326 9.37488 10.7701 9.37488 15.4251 7.7124V7.7124"
        stroke="#3B3939"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
