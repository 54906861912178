import { Stack, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  StyledSituationHeightContainer,
  StyledStackRowSituation,
} from "components/common";

export const StyledStackRow = styled(StyledStackRowSituation)(({ theme }) => {
  return {
    background: theme.palette.error.light,
    "&:hover": {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    "&.active": {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    "&.warn": {
      background: `${theme.palette.warning.light}26`,
    },
    "&.success": {
      background: theme.palette.system.green.light,
    },
  };
});

export const StyledCellTypography = styled(Typography)(({ theme }) => {
  return {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
});

export const StyledCell = styled("div")(({ theme }) => {
  return {
    width: "20%",
    fontSize: 14,
  };
});

export const StyledSituationTitle = styled(Typography)(({ theme }) => {
  return {
    fontSize: theme.spacing(4),
    lineHeight: theme.spacing(4),
    fontWeight: 500,
    color: theme.palette.black.main,
  };
});

export const StyledSituationListContainer = styled(Grid)(({ theme }) => {
  return {
    background: theme.palette.background.paper,
    width: 512,
    height: "calc(100vh - 100px)",
    borderRadius: `${theme.spacing(1)} 0px 0px ${theme.spacing(1)}`,
    overflow: "auto",
    padding: theme.spacing(2),
  };
});

export const StyledStackKns = styled(Stack)(() => {
  return {
    justifyContent: "space-between",
    height: "100%",
  };
});

export const StyledNameKns = styled("div")(({ theme }) => {
  return {
    color: theme.palette.black.main,
  };
});

export const StyledKnsItem = styled(Grid)(({ theme }) => {
  return {
    width: 160,
    height: 160,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.customGrey.main}`,
    color: theme.palette.customGrey.main,
    background: theme.palette.system.green.light,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    "&.error": {
      background: theme.palette.error.light,
      borderColor: theme.palette.error.main,
    },
  };
});

export const StyledListContainer = styled(StyledSituationHeightContainer)(
  ({ theme }) => {
    return {
      alignSelf: "flex-start",
      width: `calc(100% - 512px - ${theme.spacing(2)})`,
      marginRight: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: `0px ${theme.spacing(1)} ${theme.spacing(1)}  0px`,
    };
  },
);
