import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import theme from "lib/theme";

export const StyledDivScroll = styled(Grid)(() => {
  return {
    height: 230,
    overflow: "auto",
    width: "100%",
  };
});

export const StyledHeadCell = styled(Grid)(() => {
  return {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 500,
    color: theme.palette.customGrey.main,
  };
});

export const StyledJournal = styled(Typography)(() => {
  return {
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 500,
  };
});

export const StyledInfoContainer = styled(Grid)(({ theme }) => {
  return {
    pointerEvents: "auto",
    width: "auto",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    minHeight: 320,
    padding: theme.spacing(2),
  };
});
