import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const VolumeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <ellipse
        cx="9.55906"
        cy="3.66666"
        rx="7.99998"
        ry="2.66666"
        stroke="#3C3939"
        strokeWidth="1.5"
      />
      <path
        d="M17.559 14.3333C17.559 14.6835 17.3521 15.0303 16.9501 15.3538C16.548 15.6774 15.9588 15.9713 15.2159 16.219C14.473 16.4666 13.5911 16.663 12.6205 16.797C11.6499 16.931 10.6096 17 9.55906 17C8.50849 17 7.4682 16.931 6.4976 16.797C5.527 16.663 4.64509 16.4666 3.90222 16.219C3.15935 15.9713 2.57008 15.6774 2.16804 15.3538C1.76601 15.0303 1.55908 14.6835 1.55908 14.3333"
        stroke="#3C3939"
        strokeWidth="1.5"
      />
      <path d="M17.5591 3.66666V14.3333" stroke="#3C3939" strokeWidth="1.5" />
      <path d="M1.55908 3.66666V14.3333" stroke="#3C3939" strokeWidth="1.5" />
    </SvgIcon>
  );
};
