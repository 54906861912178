import { Stack, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSituationHeightContainer = styled(Grid)(({ theme }) => {
  return {
    background: theme.palette.background.paper,
    maxWidth: 900,
    height: "calc(100vh - 100px)",
  };
});

export const StyledSituationViewContainer = styled(
  StyledSituationHeightContainer,
)(({ theme }) => {
  return {
    alignSelf: "flex-start",
    marginRight: theme.spacing(2),
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    borderRadius: `0px ${theme.spacing(1)} ${theme.spacing(1)}  0px`,
  };
});

export const StyledStackHeadRowSituation = styled(Stack)(({ theme }) => {
  return {
    alignItems: "center",
    color: theme.palette.black.main,
    fontSize: 14,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  };
});

export const StyledStackRowSituation = styled(StyledStackHeadRowSituation)(
  ({ theme }) => {
    return {
      background: theme.palette.customGrey.light,
      cursor: "pointer",
      border: `3px solid transparent`,
      marginRight: theme.spacing(0.5),
    };
  },
);
