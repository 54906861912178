import React, { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";

import { DispatcherContainer, MapContainer } from "containers";
import { accessTypeEnum, RoutesEnum } from "lib/enums";
import { dispatcherDuck, settingsDuck } from "lib/ducks";
import { Kns } from "lib/types";

import {
  KnsItem,
  ModalKnsJournal,
  ModalSituation,
  StyledGridFilter,
} from "./components";
import { observer } from "mobx-react-lite";
import { getQueryParams, setQueryParams } from "query-params-util";
import { OrgUnitTabs } from "components/common";
import { relative } from "path";
import { KnsTimeAlarm } from "components/common/KnsTimeAlarm/KnsTimeAlarm";

export const Monitoring: FC = observer(() => {
  const [organizationUnitId, setOrgUnitId] = useState<number | null>(null);
  const profile = settingsDuck.getMe();
  const knses = dispatcherDuck
    .getAllListKns()
    .filter((kns) => {
      return organizationUnitId !== null
        ? kns.organizationUnitId === organizationUnitId
        : true;
    })
    .sort((a, b) => a.deviceCode - b.deviceCode);

  const handleChangeOrgUnit = (_: any, organizationUnitId: number | null) => {
    setOrgUnitId(organizationUnitId);
    setQueryParams({ organizationUnitId }, true);
  };

  useEffect(() => {
    const { organizationUnitId } = getQueryParams();
    if (organizationUnitId) {
      setOrgUnitId(Number(organizationUnitId));
    }

    if (organizationUnitId) {
      setOrgUnitId(Number(organizationUnitId));
    } else {
      if (!checkAdmin() && profile?.employee?.organizationUnitId) {
        setOrgUnitId(profile?.employee?.organizationUnitId);
      }
    }
  }, []);

  const checkAdmin = () => {
    return profile?.access?.includes(accessTypeEnum.admin);
  };

  return (
    <>
      <KnsTimeAlarm list={knses} />
      <MapContainer />
      <ModalKnsJournal />
      <ModalSituation />
      <DispatcherContainer active={RoutesEnum.MONITORING}>
        <Grid container item xs={12} sx={{ px: 2 }}>
          <StyledGridFilter item xs={4}>
            <OrgUnitTabs
              value={organizationUnitId}
              onChange={handleChangeOrgUnit}
            />
          </StyledGridFilter>
        </Grid>
        <Grid container sx={{ p: 2 }} gap={3}>
          {knses.map((kns: Kns) => {
            return <KnsItem kns={kns} />;
          })}
        </Grid>
      </DispatcherContainer>
    </>
  );
});
