import clsx from "clsx";
import React, { FC } from "react";
import { StyledEngineWorkContainer, StyledEngineWorkIndicator } from "./styled";

interface Props {
  on: boolean;
}
export const EngineWork: FC<Props> = ({ on }) => {
  return (
    <StyledEngineWorkContainer
      className={clsx({
        reverse: on,
      })}>
      <StyledEngineWorkIndicator
        className={clsx({
          active: on,
        })}
      />
    </StyledEngineWorkContainer>
  );
};
