import React, { FC, ReactNode, useState, useEffect, Fragment } from "react";
import { delay } from "lodash";
import { SkeletonTypeEnum } from "lib/enums";
import { SituationCard, SituationListItem } from "./components";

const map: Record<SkeletonTypeEnum, ReactNode> = {
  [SkeletonTypeEnum.SITUATION_LIST_ITEM]: <SituationListItem />,
  [SkeletonTypeEnum.SITUATION_CARD]: <SituationCard />,
};

interface Props {
  skeleton?: SkeletonTypeEnum;
  children?: ReactNode | ReactNode[];
  isLoading: boolean;
  count?: number;
}
export const SkeletonContainer: FC<Props> = ({
  isLoading,
  children,
  skeleton = SkeletonTypeEnum.SITUATION_LIST_ITEM,
  count = 20,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const component: ReactNode = map[skeleton];

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      delay(() => setLoading(false), 400);
    }
  }, [isLoading]);

  return (
    <>
      {loading
        ? Array.from({ length: count }, (_, index) => (
            <Fragment key={index}>{component}</Fragment>
          ))
        : children}
    </>
  );
};
