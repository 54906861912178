import React, { FC } from "react";
import { Skeleton, Grid } from "@mui/material";

import { StyledSituationViewContainer } from "components/common";

export const SituationCard: FC = () => {
  return (
    <StyledSituationViewContainer sx={{ width: 900 }}>
      <Grid container>
        <Grid container spacing={1}>
          <Grid container item xs={7}>
            <Grid item xs={12}>
              <Skeleton sx={{ width: "100%", height: 70 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton sx={{ width: "100%", height: 50 }} />
            </Grid>
            <Grid container spacing={1}>
              {Array(6).fill(
                <Grid item xs={6}>
                  <Skeleton sx={{ width: "100%", height: 40 }} />
                </Grid>,
              )}
            </Grid>
          </Grid>

          <Grid container item xs={5} spacing={2}>
            {Array(3).fill(
              <Grid item xs={4}>
                <Skeleton sx={{ width: "100%", height: 70 }} />
              </Grid>,
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Skeleton sx={{ width: "100%", height: 40 }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton sx={{ width: "100%", height: 100 }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton sx={{ width: "100%", height: 60 }} />
        </Grid>
        <Grid container item xs={12} justifyContent="end">
          <Grid xs={3}>
            <Skeleton sx={{ width: "100%", height: 40 }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Skeleton sx={{ width: "100%", height: 100 }} />
        </Grid>
        {Array(4).fill(
          <Grid item xs={12}>
            <Skeleton sx={{ width: "100%", height: 40 }} />
          </Grid>,
        )}
      </Grid>
    </StyledSituationViewContainer>
  );
};
