import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";

import { KnsInfoContainer } from "containers";
import { MemoSchema } from "components/common";
import { Divider } from "components/ui";
import { dispatcherDuck } from "lib/ducks";
import { Kns } from "lib/types";

import { KnsJournal, StyledInfoContainer } from "./components";

export const Info: FC = observer(() => {
  const { active } = dispatcherDuck;

  const handleClose = () => {
    dispatcherDuck.active = null;
  };

  return active !== null ? (
    <StyledInfoContainer container sx={{ position: "relative" }}>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}>
        <div style={{ width: 480 }}>
          <KnsInfoContainer kns={active as Kns} />
        </div>
        <div style={{ paddingInline: 16 }}>
          <MemoSchema kns={active as Kns} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <KnsJournal />
        </div>
      </Stack>
      <IconButton
        onClick={handleClose}
        size="small"
        sx={{ position: "absolute", right: 0, top: 8 }}>
        <Close />
      </IconButton>
    </StyledInfoContainer>
  ) : null;
});
