import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const StyledHeadInfo = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gap: theme.spacing(1),
    color: theme.palette.primary.main,
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
    fontSize: 14,
    justifyContent: "center",
    "&.error": {
      color: theme.palette.error.main,
      background: theme.palette.error.light,
    },
  };
});

export const StyledTitle = styled("div")(() => {
  return {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: "32px",
  };
});

export const StyledHeadTypography = styled(Typography)(({ theme }) => {
  return {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "16px",
    "&.active": {
      background: theme.palette.primary.light,
      paddingInline: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
    },
  };
});

export const StyledHeadItem = styled("div")(() => {
  return {
    fontWeight: 400,
  };
});

export const StyledInfoKns = styled("div")(({ theme }) => {
  return {
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(1),
    paddingLeft: 3,
    minWidth: "428px",
  };
});
