import users from "./users";
import roles from "./roles";
import orgUnits from "./orgUnits";
import locations from "./locations";
import employee from "./employee";
import kns from "./kns";

const admin = [
  ...users,
  ...roles,
  ...orgUnits,
  ...locations,
  ...employee,
  ...kns,
];

export default admin;
