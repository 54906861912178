import React, { FC, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";
import * as yup from "yup";
import { useFormik } from "formik";
import { isDate } from "date-fns";

import { Divider, LicensePlate } from "components/ui";
import { JournalVehicle } from "lib/types";

import { StyledBox } from "./styled";

const transformDate = (_: any, originalValue: any) => {
  return isDate(originalValue) && !Date.parse(originalValue)
    ? null
    : originalValue;
};

const schema = yup.object({
  vehicles: yup.array().of(
    yup.object().shape({
      journalId: yup.number().nullable(),
      licensePlate: yup
        .string()
        .transform((_: any, originalValue: string) => {
          return originalValue?.replace(/\s/g, "");
        })
        .required("Обязательное поле")
        .matches(
          /^([АВЕКМНОРСТУХ]{1}\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3})|(\d{4}[АВЕКМНОРСТУХ]{2}\d{2,3})$/,
          "Не верный форат гос.номера",
        ),
      startDate: yup
        .date()
        .nullable()
        .transform(transformDate)
        .required("Обязательное поле"),
      endDate: yup
        .date()
        .nullable()
        .transform(transformDate)
        .when("licensePlate", (licensePlate, schema) => {
          return licensePlate ? schema.required("Обязательное поле") : schema;
        })
        .when("startDate", (startDate, schema) => {
          return startDate !== null
            ? schema.min(
                startDate,
                "Дата окончания не может быть раньше даты начала",
              )
            : schema;
        }),
    }),
  ),
});

const emptyVehicle = {
  licensePlate: null,
  startDate: null,
  endDate: null,
} as JournalVehicle;

interface Props {
  open: boolean;
  onClose: () => void;
  vehicles: JournalVehicle[];
  onSubmit: (vehicles: JournalVehicle[], deleteVehicle: number[]) => void;
  readOnly: boolean;
}
export const ModalVehicles: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  vehicles,
  readOnly,
}) => {
  const [deleteVeh, setDeleteVeh] = useState<number[]>([]);

  const handleSubmit = ({ vehicles }: any) => {
    onSubmit(vehicles, deleteVeh);
  };

  const handleAddEmptyRow = () => {
    formik.setFieldValue("vehicles", [
      ...formik.values.vehicles,
      { ...emptyVehicle },
    ]);
  };

  const handleDeleteVeh = (vehicle: JournalVehicle, index: number) => () => {
    if (vehicle?.id) {
      setDeleteVeh((oldValue) => [...oldValue, Number(vehicle.id)]);
    }
    formik.setFieldValue("vehicles", [
      ...formik.values.vehicles.filter((_, ind) => ind !== index),
    ]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      vehicles: vehicles.length ? [...vehicles] : [{ ...emptyVehicle }],
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal open={open} onClose={onClose}>
      <form key="modal-vehicle-form" onSubmit={formik.handleSubmit}>
        <StyledBox>
          <Typography variant="h6" component="h2">
            Добавить запись
          </Typography>
          <Divider sx={{ mt: 2, ml: -2, mr: -2 }} />
          <Grid container sx={{ overflow: "auto", maxHeight: 400 }}>
            {formik.values.vehicles.map(
              (vehicle: JournalVehicle, index: number) => {
                let errors = {
                  licensePlate: {
                    touched: false,
                    error: null,
                  },
                  startDate: {
                    touched: false,
                    error: null,
                  },
                  endDate: {
                    touched: false,
                    error: null,
                  },
                };
                if (Array.isArray(formik.touched?.vehicles)) {
                  errors.licensePlate.touched = Boolean(
                    formik.touched.vehicles[index]?.licensePlate,
                  );
                  errors.startDate.touched = Boolean(
                    formik.touched.vehicles[index]?.startDate,
                  );
                  errors.endDate.touched = Boolean(
                    formik.touched.vehicles[index]?.endDate,
                  );
                }
                if (Array.isArray(formik.errors?.vehicles)) {
                  const veh: any = formik.errors.vehicles[index];
                  errors.licensePlate.error = veh?.licensePlate;
                  errors.startDate.error = veh?.startDate;
                  errors.endDate.error = veh?.endDate;
                }
                return (
                  <Grid
                    container
                    spacing={2}
                    sx={{ pt: 2 }}
                    key={`${vehicle?.id ?? ""}-${index}`}>
                    <Grid item xs={3}>
                      <LicensePlate
                        disabled={readOnly}
                        variant={readOnly ? "standard" : "outlined"}
                        onChange={(event) => {
                          formik.setFieldValue(
                            `vehicles[${index}].licensePlate`,
                            event.target.value,
                          );
                        }}
                        value={vehicle?.licensePlate}
                        size="small"
                        label="Номер машины"
                        error={
                          errors.licensePlate.touched &&
                          Boolean(errors.licensePlate.error)
                        }
                        helperText={
                          errors.licensePlate.touched &&
                          errors.licensePlate.error
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DateTimePicker
                        inputFormat="dd.MM.yyyy HH:mm"
                        mask="__.__.____ __:__"
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...props}
                            variant={readOnly ? "standard" : "outlined"}
                            disabled={readOnly}
                            error={
                              errors.startDate.touched &&
                              Boolean(errors.startDate.error)
                            }
                            helperText={
                              errors.startDate.touched && errors.startDate.error
                            }
                          />
                        )}
                        label="Дата начала"
                        value={vehicle?.startDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            `vehicles[${index}].startDate`,
                            date,
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DateTimePicker
                        inputFormat="dd.MM.yyyy HH:mm"
                        mask="__.__.____ __:__"
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            size="small"
                            {...props}
                            variant={readOnly ? "standard" : "outlined"}
                            disabled={readOnly}
                            error={
                              errors.endDate.touched &&
                              Boolean(errors.endDate.error)
                            }
                            helperText={
                              errors.endDate.touched && errors.endDate.error
                            }
                          />
                        )}
                        label="Дата окончания"
                        value={vehicle?.endDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            `vehicles[${index}].endDate`,
                            date,
                          );
                        }}
                      />
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={1}>
                        {index + 1 === formik.values.vehicles.length ? (
                          <IconButton
                            color="primary"
                            onClick={handleAddEmptyRow}>
                            <Add />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="error"
                            onClick={handleDeleteVeh(vehicle, index)}>
                            <Close />
                          </IconButton>
                        )}
                      </Grid>
                    )}
                  </Grid>
                );
              },
            )}
          </Grid>
          <Divider sx={{ mt: 2, mb: 2, ml: -2, mr: -2 }} />
          <Stack
            direction="row"
            justifyContent="end"
            spacing={2}
            sx={{ width: "100%", alignItems: "center" }}>
            <Button variant="contained" size="small" type="submit">
              добавить
            </Button>
            <Button size="small" onClick={onClose}>
              отмена
            </Button>
          </Stack>
        </StyledBox>
      </form>
    </Modal>
  );
};
