import React, { FC } from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";

import { DispatcherContainer, MapContainer } from "containers";
import { RoutesEnum } from "lib/enums";
import { situationDuck } from "lib/ducks";

import { KnsList, SituationList, Info } from "./components";

export const Situation: FC = observer(() => {
  const { activeSituationId } = situationDuck;
  return (
    <>
      <MapContainer disablePoint />
      <DispatcherContainer active={RoutesEnum.SITUATION}>
        <Grid container sx={{ mt: 1 }}>
          {activeSituationId ? <Info /> : <KnsList />}
          <SituationList />
        </Grid>
      </DispatcherContainer>
    </>
  );
});
