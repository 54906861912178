import { useQuery } from "react-query";
import { journalApi, ListResponse } from "lib/api";
import { FacilityTypeEnum } from "lib/enums";
import { Journal } from "lib/types";

export type FilterJournals = {
  type?: FacilityTypeEnum;
  objectId?: number;
  organizationUnitId?: number;
};
const useFetch = (
  page: number | undefined,
  pageSize: number | undefined,
  filter?: FilterJournals,
) => {
  const { isLoading, data, refetch } = useQuery(
    ["fetchJournal", page, pageSize, filter],
    () => journalApi.fetch({ page, pageSize, ...filter }),
    {
      enabled: Boolean(page) && Boolean(pageSize),
      select: (response: ListResponse<Journal>) => {
        return {
          ...response,
          data: response.data.map((journal: Journal) => {
            return {
              ...journal,
              date: journal.date ? new Date(journal.date) : null,
              objectId: journal.knsId,
              vehicles: journal.vehicles.map((vehicle) => ({
                ...vehicle,
                startDate: vehicle.startDate
                  ? new Date(vehicle.startDate)
                  : null,
                endDate: vehicle.endDate ? new Date(vehicle.endDate) : null,
              })),
            };
          }),
        };
      },
    },
  );
  return { isLoading, data, refetch };
};

export default useFetch;
