import React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { Employee, OrgUnit } from "lib/types";
import { OrgUnitSelect, PositionSelect } from "components/selects";
import { StyledDiv } from "components/ui";

const validationSchema = yup.object({
  firstName: yup.string().required("Обязательное поле"),
  middleName: yup.string().required("Обязательное поле"),
  lastName: yup.string().required("Обязательное поле"),
  organizationUnitId: yup.number().required("Обязательное поле"),
  position: yup.number().required("Обязательное поле"),
});

type Props = {
  handleSubmit: (employee: Employee) => void;
  handleCancel: () => void;
  employee: Employee;
  isLoading: boolean;
};
const InnerForm = ({
  handleSubmit,
  employee,
  isLoading,
  handleCancel,
}: Props) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...employee,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e);
  };
  const isSend = Boolean(formik.submitCount);
  return (
    <StyledDiv>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="firstName"
              name="firstName"
              label="Фамилия"
              type="text"
              InputProps={{
                autoComplete: "off",
              }}
              value={formik.values?.firstName ?? ""}
              onChange={formik.handleChange}
              error={
                (formik.touched.firstName || isSend) &&
                Boolean(formik.errors.firstName)
              }
              helperText={
                (formik.touched.firstName || isSend) && formik.errors.firstName
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="middleName"
              name="middleName"
              label="Имя"
              type="text"
              InputProps={{
                autoComplete: "off",
              }}
              value={formik.values?.middleName ?? ""}
              onChange={formik.handleChange}
              error={
                (formik.touched.middleName || isSend) &&
                Boolean(formik.errors.middleName)
              }
              helperText={
                (formik.touched.middleName || isSend) &&
                formik.errors.middleName
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="lastName"
              name="lastName"
              label="Отчество"
              type="text"
              InputProps={{
                autoComplete: "off",
              }}
              value={formik.values?.lastName ?? ""}
              onChange={formik.handleChange}
              error={
                (formik.touched.lastName || isSend) &&
                Boolean(formik.errors.lastName)
              }
              helperText={
                (formik.touched.lastName || isSend) && formik.errors.lastName
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OrgUnitSelect
              value={formik.values?.organizationUnitId ?? ""}
              onChange={(
                id: number | number[] | undefined,
                _orgUnit: OrgUnit | undefined,
              ) => {
                formik.setFieldValue("organizationUnitId", id);
              }}
              error={
                (formik.touched.organizationUnitId || isSend) &&
                Boolean(formik.errors.organizationUnitId)
              }
              helperText={
                (formik.touched.organizationUnitId || isSend) &&
                formik.errors.organizationUnitId
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PositionSelect
              value={formik.values?.position ?? ""}
              onChange={(e) => {
                formik.setFieldValue("position", e.target.value);
              }}
              error={
                (formik.touched.position || isSend) &&
                Boolean(formik.errors.position)
              }
              helperText={
                (formik.touched.position || isSend) && formik.errors.position
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              type="submit">
              Cохранить
            </LoadingButton>
            <LoadingButton
              onClick={handleCancel}
              loading={isLoading}
              style={{ float: "right" }}>
              Отменить
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </StyledDiv>
  );
};
export default InnerForm;
