import React, { FC } from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";

import { KnsSituationContainer } from "containers";
import { situationDuck } from "lib/ducks";

export const Info: FC = observer(() => {
  const { activeSituationId } = situationDuck;

  const handleCLose = () => {
    situationDuck.activeSituationId = null;
  };

  return activeSituationId ? (
    <>
      <KnsSituationContainer
        situationId={activeSituationId}
        onClose={handleCLose}
      />
      <Grid flexGrow={1} />
    </>
  ) : null;
});
