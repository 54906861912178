import React, { FC } from "react";

import { useSituations } from "lib/hooks";
import { Situation } from "lib/types";

import { AlertItem, StyledDivContainer } from "./components";

export const SituationAlertContainer: FC = () => {
  const { data: situations } = useSituations(1, 20);
  return (situations?.totalCount ?? 0) > 0 && false ? (
    <StyledDivContainer>
      {situations?.data?.map((situation: Situation) => {
        return <AlertItem situation={situation} />;
      })}
    </StyledDivContainer>
  ) : null;
};
