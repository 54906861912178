import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Button, CardContent, Dialog, DialogTitle } from "@mui/material";
import {
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { Header } from "../../components/layouts";
import { useDeleteOrgUnit, useOrgUnits } from "../../lib/hooks";
import { Card, Breadcrumbs, BaseDataGrid } from "../../components/ui";
import { OrgUnit } from "../../lib/types";
import { ConfirmDialog } from "components/common";

const route = "/admin/org-units";
const title = "Подразделения";
const List = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>();
  const { isLoading, data, refetch } = useOrgUnits(page);
  const mutation = useDeleteOrgUnit();
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const deleteRecord = () => {
    deleteId &&
      mutation.mutate(deleteId, {
        onSuccess: () => {
          refetch();
        },
      });
  };

  const handleSetDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const handleCancel = () => {
    setDeleteId(null);
  };

  const handleDelete = async () => {
    await deleteRecord();
    handleCancel();
  };

  const columns = [
    {
      field: "name",
      flex: 1,
      headerName: "Наименование",
      sortable: false,
    },
    {
      field: "children",
      flex: 1,
      headerName: "Потомки",
      sortable: false,
      valueGetter: ({ value }: GridValueGetterParams) => {
        return value?.map(({ name }: any) => name).join(", ") ?? "-";
      },
    },
    {
      field: "id",
      type: "actions",
      getActions: (params: GridRowParams<OrgUnit>) => {
        return [
          <GridActionsCellItem
            label="Удалить"
            onClick={() => handleSetDeleteId(params.row.id)}
            icon={<DeleteIcon />}
          />,
        ];
      },
    },
  ];
  const hanleChangePage = (page: number) => {
    setPage(page);
  };
  return (
    <>
      <ConfirmDialog
        isLoading={mutation.isLoading}
        open={Boolean(deleteId)}
        onCancel={handleCancel}
        onSubmit={handleDelete}
      />
      <Header
        title={title}
        right={
          <Button onClick={() => navigate(`${route}/new`)}>Создать</Button>
        }
      />
      <Breadcrumbs crumbs={[{ label: "Главная", to: "/" }, { label: title }]} />
      <Card>
        <CardContent>
          <BaseDataGrid
            style={{ height: "calc(100vh - 235px)" }}
            loading={isLoading}
            columns={columns}
            rows={data?.data}
            page={page}
            hanleChangePage={hanleChangePage}
            rowCount={data?.totalCount}
            onRowClick={({ row }: any) => {
              navigate(`${route}/${row.id}/edit`);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
export default List;
