import React, { FC, useState } from "react";
import { Edit } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

import { EmployeeSelect as Select } from "components/selects";
import { Employee } from "lib/types";
import { StyledStackItem, StyledBtn } from "./styled";

interface Props {
  employee?: Employee | null;
  employeeId?: number | null;
  error: boolean | undefined;
  onChange: (
    id: number | number[] | undefined,
    employee: Employee | undefined,
  ) => void;
  readOnly: boolean;
}
export const EmployeeSelect: FC<Props> = ({
  employee,
  employeeId,
  onChange,
  error,
  readOnly,
}) => {
  const [showSelect, setShow] = useState<boolean>(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleChange = (
    id: number | number[] | undefined,
    employee: Employee | undefined,
  ) => {
    onChange(id, employee);
  };

  const handleClear = () => {
    onChange(undefined, undefined);
  };

  return (
    <>
      <Typography color={error ? "error.main" : undefined}>
        Исполнитель:{" "}
      </Typography>

      {showSelect ? (
        <StyledStackItem sx={{ pl: 2 }}>
          <Select
            variant="standard"
            size="small"
            label={""}
            value={employeeId}
            onChange={handleChange}
            error={error}
          />
          <StyledBtn size="small" color="primary" onClick={handleHide}>
            скрыть
          </StyledBtn>
          <StyledBtn size="small" color="error" onClick={handleClear}>
            очистить
          </StyledBtn>
        </StyledStackItem>
      ) : (
        <>
          {employee ? (
            <StyledStackItem sx={{ pl: 2 }}>
              {employee.firstName} {employee.middleName} {employee.lastName}
              {!readOnly && (
                <IconButton size="small" color="primary" onClick={handleShow}>
                  <Edit fontSize="small" />
                </IconButton>
              )}
            </StyledStackItem>
          ) : (
            <StyledBtn
              underline
              size="small"
              onClick={handleShow}
              disabled={readOnly}
              color={error ? "error" : undefined}>
              отсутствует
            </StyledBtn>
          )}
        </>
      )}
    </>
  );
};
