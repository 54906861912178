import React, { FC, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

import { SkeletonContainer } from "containers/skeleton";
import { StyledStackHeadRowSituation } from "components/common";
import { Divider, TablePagination } from "components/ui";

import { SituationStatus, SituationStatusEnum } from "lib/enums";
import { useSituations } from "lib/hooks";
import { Situation } from "lib/types";
import { localeDate, localeTime } from "lib/helpers";

import {
  StyledSituationTitle,
  StyledStackRow,
  StyledCell,
  StyledCellTypography,
} from "./styled";
import { situationDuck } from "lib/ducks";

interface Props {
  knsIds?: number | null;
  activeSituationId?: number | null;
  onClick: (situation: Situation) => void;
}
export const SituationListContainer: FC<Props> = observer(
  ({ knsIds, activeSituationId, onClick }) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const { isLoading, isFetching, refetch, ...situations } = useSituations(
      page,
      pageSize,
      {
        knsIds,
      },
    );

    const handleClick = (activeSituation: Situation) => () => {
      onClick(activeSituation);
    };

    const handleChangePage = (_: any, value: number) => {
      const page = value + 1;
      setPage(page);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setPageSize(parseInt(event.target.value, 10));
      setPage(1);
    };

    useEffect(() => {
      if (situationDuck.refetchSituationListContainer) {
        refetch();
        situationDuck.refetchSituationListContainer = false;
      }
    }, [situationDuck.refetchSituationListContainer]);

    return (
      <Grid container>
        <Grid item xs={12}>
          <StyledSituationTitle>Сообщения о ситуациях</StyledSituationTitle>
          <Divider sx={{ mt: 2 }} />
          <StyledStackHeadRowSituation direction="row" spacing={1}>
            <StyledCell>Дата/время</StyledCell>
            <StyledCell>Объект</StyledCell>
            <StyledCell>Взято в работу</StyledCell>
            <StyledCell>Диспетчер</StyledCell>
            <StyledCell>Причина</StyledCell>
            <StyledCell>Статус</StyledCell>
          </StyledStackHeadRowSituation>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ overflow: "auto", height: "calc(100vh - 292px)" }}>
          <SkeletonContainer
            isLoading={isLoading || isFetching}
            count={pageSize}>
            {situations?.data?.totalCount === 0 && (
              <Grid container>
                <Grid item xs={12} justifyContent="center">
                  <Divider />
                  <Typography align="center">Нет данных</Typography>
                </Grid>
              </Grid>
            )}

            {situations?.data?.data?.map(
              (situation: Situation, index: number) => {
                return (
                  <StyledStackRow
                    direction="row"
                    spacing={1}
                    key={`row-${situation.id}-${index}`}
                    onClick={handleClick(situation)}
                    className={clsx({
                      active: situation.id === activeSituationId,
                      warn: [SituationStatusEnum.WORK].includes(
                        situation.status,
                      ),
                      success: [
                        SituationStatusEnum.RESOLVED,
                        SituationStatusEnum.CLOSED,
                      ].includes(situation.status),
                    })}>
                    <StyledCell>
                      <StyledCellTypography>
                        {localeDate(situation?.startDate ?? "")}
                      </StyledCellTypography>
                      <StyledCellTypography color="customGrey.main">
                        {localeTime(situation?.startDate ?? "")}
                      </StyledCellTypography>
                    </StyledCell>

                    <StyledCell>
                      <StyledCellTypography>
                        {situation.kns.name}
                      </StyledCellTypography>
                      <StyledCellTypography color="customGrey.main">
                        {situation.kns?.location?.shortAddress ?? "-"}
                      </StyledCellTypography>
                    </StyledCell>

                    <StyledCell>
                      <StyledCellTypography>
                        {situation.startWorkDate ? (
                          <>
                            <StyledCellTypography>
                              {localeDate(situation?.startWorkDate ?? "")}
                            </StyledCellTypography>
                            <StyledCellTypography color="customGrey.main">
                              {localeTime(situation?.startWorkDate ?? "")}
                            </StyledCellTypography>
                          </>
                        ) : (
                          <StyledCellTypography color="error">
                            {SituationStatus[situation.status]}
                          </StyledCellTypography>
                        )}
                      </StyledCellTypography>
                    </StyledCell>

                    <StyledCell>-</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>
                      <StyledCellTypography
                        color={
                          situation.status === SituationStatusEnum.DRAFT
                            ? "error"
                            : "default"
                        }>
                        {SituationStatus[situation.status]}
                      </StyledCellTypography>
                    </StyledCell>
                  </StyledStackRow>
                );
              },
            )}
          </SkeletonContainer>
        </Grid>
        <Grid item xs={12}>
          <TablePagination
            pageSize={pageSize}
            totalCount={situations?.data?.totalCount ?? 0}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    );
  },
);
