import React, { useState } from "react";
import { LatLng } from "leaflet";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { Location } from "../../../lib/types";
import { StyledDiv } from "../../../components/ui";
import Map from "./Map";
import { osmApi } from "../../../lib/api";
import { debounce } from "lodash";

const validationSchema = yup.object({
  name: yup.string().required("Обязательное поле"),
  address: yup.string().required("Обязательное поле"),
  shortAddress: yup.string().required("Обязательное поле"),
  lat: yup.number().required("Обязательное поле"),
  lon: yup.number().required("Обязательное поле"),
  radius: yup
    .number()
    .moreThan(0, ({ more }) => `Радиус должен быть больше чем ${more}`)
    .required("Обязательное поле"),
});

type Props = {
  handleSubmit: (location: Location) => void;
  handleCancel: () => void;
  location: Location;
  isLoading: boolean;
};
const InnerForm = ({
  handleSubmit,
  location,
  isLoading,
  handleCancel,
}: Props) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: location,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e);
  };
  const handleSearch = async (event: any) => {
    if (event?.type === "change") {
      try {
        setLoading(true);
        const search = event?.target?.value ?? null;
        formik.setFieldValue("address", search);
        const data = search ? await osmApi.search(search) : [];
        setOptions(
          data.map((item: any) => ({
            ...item,
            title: item.display_name,
          })),
        );
      } catch (error) {
        console.error("Ошибка запроса адреса");
      } finally {
        setLoading(false);
      }
    }
  };
  const handleClickMap = async ({ lat, lng }: LatLng) => {
    formik.setFieldValue("lat", lat);
    formik.setFieldValue("lon", lng);
    const osmData = await osmApi.reverse(lat, lng);
    formik.setFieldValue("address", osmData?.display_name ?? "");
  };
  const debounceHandleSearch = debounce(handleSearch, 500);
  const isSend = Boolean(formik.submitCount);
  return (
    <StyledDiv>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="name"
              name="name"
              label="Наименование подразделения"
              type="text"
              InputProps={{
                autoComplete: "off",
              }}
              value={formik.values?.name ?? ""}
              onChange={formik.handleChange}
              error={
                (formik.touched.name || isSend) && Boolean(formik.errors.name)
              }
              helperText={(formik.touched.name || isSend) && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Map
              onClick={handleClickMap}
              position={{
                lat: formik?.values?.lat,
                lng: formik?.values?.lon,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="address"
              size="small"
              onInputChange={debounceHandleSearch}
              loading={loading}
              filterOptions={(x) => x}
              disableClearable
              value={{
                title: formik?.values?.address ?? "",
              }}
              onChange={(event, osmValue: any) => {
                formik.setFieldValue("address", osmValue.title);
                formik.setFieldValue("lat", osmValue.lat);
                formik.setFieldValue("lon", osmValue.lon);
              }}
              getOptionLabel={(option: any) =>
                typeof option === "string" ? option : option.title
              }
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Полный адрес"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  error={
                    (formik.touched.address || isSend) &&
                    Boolean(formik.errors.address)
                  }
                  helperText={
                    (formik.touched.address || isSend) && formik.errors.address
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="shortAddress"
              name="shortAddress"
              label="Адрес"
              type="text"
              InputProps={{
                autoComplete: "off",
              }}
              onChange={formik.handleChange}
              value={formik.values?.shortAddress ?? ""}
              error={
                (formik.touched.shortAddress || isSend) &&
                Boolean(formik.errors.shortAddress)
              }
              helperText={
                (formik.touched.shortAddress || isSend) &&
                formik.errors.shortAddress
              }
            />
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                id="lat"
                name="lat"
                label="Широта"
                type="text"
                InputProps={{
                  autoComplete: "off",
                }}
                disabled
                value={formik.values?.lat ?? ""}
                error={
                  (formik.touched.lat || isSend) && Boolean(formik.errors.lat)
                }
                helperText={(formik.touched.lat || isSend) && formik.errors.lat}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                size="small"
                id="lon"
                name="lon"
                label="Долгота"
                type="text"
                InputProps={{
                  autoComplete: "off",
                }}
                value={formik.values?.lon ?? ""}
                error={
                  (formik.touched.lon || isSend) && Boolean(formik.errors.lon)
                }
                helperText={(formik.touched.lon || isSend) && formik.errors.lon}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              onChange={formik.handleChange}
              size="small"
              id="radius"
              name="radius"
              label="Радиус"
              type="number"
              InputProps={{
                autoComplete: "off",
                // inputProps: { min: 0 },
              }}
              value={formik.values?.radius ?? ""}
              error={
                (formik.touched.radius || isSend) &&
                Boolean(formik.errors.radius)
              }
              helperText={
                (formik.touched.radius || isSend) && formik.errors.radius
              }
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              type="submit">
              Cохранить
            </LoadingButton>
            <LoadingButton
              onClick={handleCancel}
              loading={isLoading}
              style={{ float: "right" }}>
              Отменить
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </StyledDiv>
  );
};
export default InnerForm;
