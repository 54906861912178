import React, { useEffect, useState } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  SxProps,
  Theme,
  Skeleton,
} from "@mui/material";

import { TablePagination } from "components/ui";
import { getQueryParams, setQueryParams } from "query-params-util";
import { HeaderTableCell, BodyTableCell } from "./styled";

type ColumnsProps<T> = {
  title: string;
  width?: number | undefined;
  field: string;
  render?: (field: any, record: T) => any;
};
type PaginationProps = {
  pageSize: number | undefined;
  totalCount: number;
  page: number | undefined;
  hanleChangePage: (page: number) => void;
  hanleChangePerPage: (pageSize: number) => void;
};
type Props<T> = {
  columns: ColumnsProps<T>[];
  data: T[];
  isLoading: boolean;
  pagination?: PaginationProps;
  tableContainerSx?: SxProps<Theme>;
  withOutQueryParams?: boolean;
};
const StyledTable = <T,>({
  columns,
  data,
  tableContainerSx,
  isLoading,
  withOutQueryParams = false,
  pagination: {
    pageSize,
    totalCount,
    page,
    hanleChangePage,
    hanleChangePerPage,
  } = {
    pageSize: 10,
    page: 0,
    totalCount: 0,
    hanleChangePage: () => {},
    hanleChangePerPage: () => {},
  },
}: Props<T>) => {
  const [random, setRandom] = useState("");

  const onPageChange = (_: any, value: number) => {
    const page = value + 1;
    !withOutQueryParams && setQueryParams({ page });
    hanleChangePage(page);
  };

  const onRowsPerPageChange = (e: any) => {
    const size = e?.target?.value ?? 20;
    !withOutQueryParams && setQueryParams({ pageSize: size });
    hanleChangePerPage(size);
  };

  useEffect(() => {
    const { page, pageSize: size } = getQueryParams();
    if (Number.isInteger(Number(page))) {
      hanleChangePage(Number(page));
    } else {
      hanleChangePage(1);
    }
    if (Number.isInteger(Number(size))) {
      hanleChangePerPage(Number(size));
    } else {
      hanleChangePerPage(pageSize ?? 20);
    }
    setRandom(`${Math.floor(Math.random() * 100)}`);
  }, []);

  return (
    <>
      <TableContainer sx={tableContainerSx} key={`table-container-${random}`}>
        <Table key={`table-${random}`} stickyHeader sx={{ width: "100%" }}>
          <TableHead key={`table-head-${random}`}>
            <TableRow key={`table-row-${random}`}>
              {columns.map(({ title, width }, idx: number) => {
                return (
                  <HeaderTableCell
                    key={`header-table-cell-${random}-${idx}`}
                    style={{ width }}>
                    {title}
                  </HeaderTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              Array(pageSize ?? 20)
                .fill(null)
                .map((_, idx) => {
                  return (
                    <TableRow key={`row-skeleton-${random}-${idx}`}>
                      {columns.map((_, index) => {
                        return (
                          <BodyTableCell
                            key={`row-cell-skeleton-${random}-${index}`}
                            align="inherit"
                            vertical="top">
                            <Skeleton />
                          </BodyTableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : data.length ? (
              data.map((row: any, idx: number) => {
                return (
                  <TableRow
                    key={`row-data-${random}-${idx}-${row?.id ?? idx}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                    {columns.map(({ field, render }, index) => {
                      return (
                        <BodyTableCell
                          key={`row-cell-data-${random}-${idx}-${index}-${
                            row?.id ?? index
                          }`}
                          align="inherit"
                          vertical="top">
                          {render ? render(row[field], row) : row[field]}
                        </BodyTableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <BodyTableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}>
                  Нет данных
                </BodyTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        pageSize={pageSize}
        totalCount={totalCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};
export default StyledTable;
