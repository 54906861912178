import { KnsList, Monitoring, Journal, Situation } from "pages";
import { accessTypeEnum, RoutesEnum } from "lib/enums";

export default [
  {
    component: KnsList,
    path: "/",
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
  {
    component: KnsList,
    path: `/${RoutesEnum.KNS}`,
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
  {
    component: Monitoring,
    path: `/${RoutesEnum.MONITORING}`,
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
  {
    component: Situation,
    path: `/${RoutesEnum.SITUATION}`,
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
  {
    component: Journal,
    path: `/${RoutesEnum.JOURNAL}`,
    access: [accessTypeEnum.admin, accessTypeEnum.read, accessTypeEnum.write],
  },
];
