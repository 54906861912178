import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import { SituationListContainer } from "containers";
import { situationDuck } from "lib/ducks";

import { StyledSituationListContainer } from "./styled";
import { Situation } from "lib/types";

export const SituationList: FC = observer(() => {
  const handleClick = (active: Situation) => {
    situationDuck.activeSituationId = active.id;
  };
  return (
    <StyledSituationListContainer item>
      <SituationListContainer
        activeSituationId={situationDuck.activeSituationId}
        onClick={handleClick}
      />
    </StyledSituationListContainer>
  );
});
