import { useQuery } from 'react-query';
import { knsApi } from '../../api';

const useGet = (id: number) => {
  const { isLoading, data } = useQuery(
    ['getKns', id],
    () => {
      return knsApi.get(id);
    },
    {
      enabled: Boolean(id),
    },
  );
  return { isLoading, data };
};

export default useGet;
