import React, { FC } from "react";

import { RoutesEnum } from "lib/enums";
import { DispatcherContainer, JournalContainer } from "containers";
import { Grid, Paper } from "@mui/material";

export const Journal: FC = () => {
  return (
    <DispatcherContainer active={RoutesEnum.JOURNAL}>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, m: 2 }}>
            <JournalContainer />
          </Paper>
        </Grid>
      </Grid>
    </DispatcherContainer>
  );
};
