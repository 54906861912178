import React, { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";

interface Props {
  open: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const ConfirmDialog: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  isLoading,
}) => {
  const handleCancel = () => {
    onCancel();
  };
  const handleOk = () => {
    onSubmit();
  };
  return (
    <Dialog open={open} maxWidth="xs">
      <DialogContent dividers>
        <Typography>Вы подтверждаете свои действия?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          color="error"
          disabled={isLoading}>
          отмена
        </Button>
        <Button onClick={handleOk} color="success" disabled={isLoading}>
          <Stack direction="row" alignItems="center" gap={1}>
            {isLoading && <CircularProgress size={10} />}
            <span>ок</span>
          </Stack>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
