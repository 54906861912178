import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 24" {...props}>
      <line y1="1.25" x2="16" y2="1.25" stroke="#3B3939" strokeWidth="1.5" />
      <line
        y1="6.58334"
        x2="13"
        y2="6.58334"
        stroke="#3B3939"
        strokeWidth="1.5"
      />
      <line
        y1="11.9167"
        x2="10"
        y2="11.9167"
        stroke="#3B3939"
        strokeWidth="1.5"
      />
      <line y1="17.25" x2="7" y2="17.25" stroke="#3B3939" strokeWidth="1.5" />
    </SvgIcon>
  );
};
