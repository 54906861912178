import { makeAutoObservable } from "mobx";

class Monitoring {
  // выбранная кнс для просмотра ситуаций
  public knsId: number | null = null;
  // выбранная ситуация
  public activeSituationId: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }
}

const monitoring = new Monitoring();

export default monitoring;
