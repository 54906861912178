import React, { FC, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { isDate } from "date-fns";
import { Button, Grid, TextField, Skeleton } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

import { Employee, Journal, JournalVehicle, Kns, Vns } from "lib/types";
import {
  EmployeeSelect,
  KnsVnsSelect,
  ReasonTypeSelect,
} from "components/selects";
import { FacilityTypeEnum } from "lib/enums";

import { ButtonContainer } from "./elements";

const getValidationSchema = (withOutObject: boolean) => {
  const transformDate = (_: any, originalValue: any) => {
    return isDate(originalValue) && !Date.parse(originalValue)
      ? null
      : originalValue;
  };

  const schema = {
    id: yup.number().nullable(),
    date: yup
      .date()
      .nullable()
      .transform(transformDate)
      .required("Обязательное поле"),
    employeeId: yup.string().nullable().required("Обязательное поле"),
    reason: yup.string().nullable().required("Обязательное поле"),
    solution: yup.string().nullable().required("Обязательное поле"),
    vehicles: yup.array().of(
      yup.object().shape({
        journalId: yup.number().nullable(),
        licensePlate: yup
          .string()
          .nullable()
          .matches(
            /^([АВЕКМНОРСТУХ]{1}\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3})|(\d{4}[АВЕКМНОРСТУХ]{2}\d{2,3})$/,
            "Не верный форат гос.номера",
          ),
        startDate: yup
          .date()
          .nullable()
          .transform(transformDate)
          .when("licensePlate", (licensePlate, schema) => {
            return licensePlate ? schema.required("Обязательное поле") : schema;
          }),
        endDate: yup
          .date()
          .nullable()
          .transform(transformDate)
          .when("licensePlate", (licensePlate, schema) => {
            return licensePlate ? schema.required("Обязательное поле") : schema;
          })
          .when("startDate", (startDate, schema) => {
            return startDate !== null
              ? schema.min(
                  startDate,
                  "Дата окончания не может быть раньше даты начала",
                )
              : schema;
          }),
      }),
    ),
  };

  return yup.object(
    withOutObject
      ? schema
      : {
          ...schema,
          objectId: yup.number().nullable().required("Обязательное поле"),
        },
  );
};

const emptyVehicle = { licensePlate: null, startDate: null, endDate: null };
const emptyJournal = {
  date: null,
  locationId: null,
  employeeId: null,
  reason: null,
  solution: null,
  vehicles: [{ ...emptyVehicle }],
};

type Props = {
  isLoading: boolean;
  isLoadingJournal?: boolean;
  handleCancel: () => void;
  handleSubmit: (
    journal: Journal,
    deleteJournalVehicleIndexes: number[],
  ) => void;
  hiddenKnsVnsSelect?: boolean;
  initialValues?: Journal | null;
};

export const JournalForm: FC<Props> = ({
  handleCancel,
  isLoading,
  handleSubmit,
  hiddenKnsVnsSelect = false,
  isLoadingJournal = false,
  initialValues = {} as Journal,
}) => {
  const [deleteJournalVehicleIndexes, setDelete] = useState<number[]>([]);

  const onSubmit = async (values: Journal) => {
    await handleSubmit(values, deleteJournalVehicleIndexes);
    formik.resetForm({});
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: (initialValues
      ? {
          ...initialValues,
          vehicles: initialValues?.vehicles?.length
            ? initialValues.vehicles
            : [{ ...emptyVehicle, journalId: initialValues?.id }],
        }
      : emptyJournal) as Journal,
    validationSchema: getValidationSchema(hiddenKnsVnsSelect),
    onSubmit,
  });

  const deleteJournalVehicle = (vehicle: JournalVehicle, index: number) => {
    if (vehicle?.id) {
      setDelete((oldValue) => [...oldValue, Number(vehicle.id)]);
    }
    formik.setFieldValue("vehicles", [
      ...formik.values.vehicles.filter((_, ind) => ind !== index),
    ]);
  };

  const isSend = Boolean(formik.submitCount);
  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoadingJournal ? (
        <SkeletonForm />
      ) : (
        <>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            <Grid item md={3}>
              <DateTimePicker
                inputFormat="dd.MM.yyyy HH:mm"
                mask="__.__.____ __:__"
                renderInput={(props) => (
                  <TextField
                    fullWidth
                    size="small"
                    {...props}
                    error={
                      (formik.touched.date || isSend) &&
                      Boolean(formik.errors.date)
                    }
                    helperText={
                      (formik.touched.date || isSend) && formik.errors.date
                    }
                  />
                )}
                label="Время заявки"
                value={formik.values?.date}
                onChange={(date) => {
                  formik.setFieldValue("date", date);
                }}
              />
            </Grid>
            {!Boolean(hiddenKnsVnsSelect) && (
              <Grid item md={3}>
                <KnsVnsSelect
                  facilityType={formik.values.type}
                  onChange={(
                    id: number | number[] | undefined,
                    obj: Kns | Vns | undefined,
                  ) => {
                    formik.setFieldValue("objectId", id);
                    if (obj?.type === FacilityTypeEnum.KNS) {
                      formik.setFieldValue("knsId", id);
                    } else {
                      formik.setFieldValue("vnsId", id);
                    }
                    formik.setFieldValue("type", obj?.type ?? null);
                  }}
                  value={formik.values?.objectId ?? ""}
                  error={
                    (formik.touched.objectId || isSend) &&
                    Boolean(formik.errors.objectId)
                  }
                  helperText={
                    (formik.touched.objectId || isSend) &&
                    formik.errors.objectId
                  }
                />
              </Grid>
            )}
            <Grid item md={3}>
              <EmployeeSelect
                onChange={(
                  id: number | number[] | undefined,
                  employee: Employee | undefined,
                ) => {
                  formik.setFieldValue("employeeId", id);
                }}
                value={formik.values?.employeeId ?? ""}
                error={
                  (formik.touched.employeeId || isSend) &&
                  Boolean(formik.errors.employeeId)
                }
                helperText={
                  (formik.touched.employeeId || isSend) &&
                  formik.errors.employeeId
                }
              />
            </Grid>
            <Grid item md={3}>
              <ReasonTypeSelect
                value={formik.values?.reason ?? ""}
                onChange={(e) => {
                  formik.setFieldValue("reason", e.target.value);
                }}
                error={
                  (formik.touched.reason || isSend) &&
                  Boolean(formik.errors.reason)
                }
                helperText={
                  (formik.touched.reason || isSend) && formik.errors.reason
                }
              />
            </Grid>
          </Grid>

          <Grid
            sx={{ mt: 1, maxHeight: 280, overflow: "auto" }}
            container
            spacing={2}>
            {formik.values.vehicles.map((vehicle, index) => {
              let errors = {
                licensePlate: {
                  touched: false,
                  error: null,
                },
                startDate: {
                  touched: false,
                  error: null,
                },
                endDate: {
                  touched: false,
                  error: null,
                },
              };
              if (Array.isArray(formik.touched?.vehicles)) {
                errors.licensePlate.touched = Boolean(
                  formik.touched.vehicles[index]?.licensePlate,
                );
                errors.startDate.touched = Boolean(
                  formik.touched.vehicles[index]?.startDate,
                );
                errors.endDate.touched = Boolean(
                  formik.touched.vehicles[index]?.endDate,
                );
              }
              if (Array.isArray(formik.errors?.vehicles)) {
                const veh: any = formik.errors.vehicles[index];
                errors.licensePlate.error = veh?.licensePlate;
                errors.startDate.error = veh?.startDate;
                errors.endDate.error = veh?.endDate;
              }
              return (
                <Grid container item md={12} spacing={2}>
                  <Grid item md={3}>
                    <TextField
                      fullWidth
                      size="small"
                      id={`vehicles[${index}].licensePlate`}
                      name={`vehicles[${index}].licensePlate`}
                      label="Транспорт"
                      type="text"
                      InputProps={{
                        autoComplete: "off",
                      }}
                      value={vehicle?.licensePlate || ""}
                      onChange={formik.handleChange}
                      error={
                        errors.licensePlate.touched &&
                        Boolean(errors.licensePlate.error)
                      }
                      helperText={
                        errors.licensePlate.touched && errors.licensePlate.error
                      }
                    />
                  </Grid>
                  <Grid item md={3}>
                    <DateTimePicker
                      inputFormat="dd.MM.yyyy HH:mm"
                      mask="__.__.____ __:__"
                      renderInput={(props) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...props}
                          error={
                            errors.startDate.touched &&
                            Boolean(errors.startDate.error)
                          }
                          helperText={
                            errors.startDate.touched && errors.startDate.error
                          }
                        />
                      )}
                      label="Дата начала"
                      value={vehicle?.startDate}
                      onChange={(date) => {
                        formik.setFieldValue(
                          `vehicles[${index}].startDate`,
                          date,
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <DateTimePicker
                      inputFormat="dd.MM.yyyy HH:mm"
                      mask="__.__.____ __:__"
                      renderInput={(props) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...props}
                          error={
                            errors.endDate.touched &&
                            Boolean(errors.endDate.error)
                          }
                          helperText={
                            errors.endDate.touched && errors.endDate.error
                          }
                        />
                      )}
                      label="Дата окончания"
                      value={vehicle?.endDate}
                      onChange={(date) => {
                        formik.setFieldValue(
                          `vehicles[${index}].endDate`,
                          date,
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    {index + 1 === formik.values.vehicles.length ? (
                      <Button
                        key={`add-${index}`}
                        onClick={() => {
                          formik.setFieldValue("vehicles", [
                            ...formik.values.vehicles,
                            { ...emptyVehicle },
                          ]);
                        }}>
                        + Добавить транспорт
                      </Button>
                    ) : (
                      <Button
                        key={`delete-${index}`}
                        color="error"
                        onClick={() => {
                          deleteJournalVehicle(vehicle, index);
                        }}>
                        Удалить
                      </Button>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Grid sx={{ mt: 1, mb: 1 }} container spacing={2}>
            <Grid item md={9}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Меры устранения"
                multiline
                rows={4}
                name="solution"
                value={formik.values?.solution}
                onChange={formik.handleChange}
                error={
                  formik.touched.solution && Boolean(formik.errors.solution)
                }
                helperText={formik.touched.solution && formik.errors.solution}
              />
            </Grid>
          </Grid>
        </>
      )}

      <ButtonContainer>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          color="primary"
          variant="contained"
          type="submit">
          Cохранить
        </LoadingButton>
        <LoadingButton onClick={handleCancel} loading={isLoading}>
          Отменить
        </LoadingButton>
      </ButtonContainer>
    </form>
  );
};

const SkeletonForm = () => {
  return (
    <>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
        <Grid item md={3}>
          <Skeleton />
        </Grid>
      </Grid>

      <Grid
        sx={{ mt: 1, maxHeight: 280, overflow: "auto" }}
        container
        spacing={2}>
        <Grid container item md={12} spacing={2}>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
          <Grid item md={3}>
            <Skeleton />
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ mt: 1, mb: 1 }} container spacing={2}>
        <Grid item md={9}>
          <Skeleton height="100" />
        </Grid>
      </Grid>
    </>
  );
};
