import { LatLng } from "leaflet";
import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMapEvents, Marker } from "react-leaflet";

type Props = {
  onClick: (latLng: LatLng) => void;
  position: {
    lat: number | undefined;
    lng: number | undefined;
  };
};
const SearchCenter = (props: Props) => {
  const map = useMapEvents({
    click(e) {
      props.onClick(e.latlng);
    },
    locationfound(e) {
      map.setView(e.latlng, map.getZoom());
    },
  });
  useEffect(() => {
    map.locate();
  }, []);
  useEffect(() => {
    if (props.position.lat && props.position.lng) {
      map.setView([Number(props.position.lat), Number(props.position.lng)]);
    }
  }, [props.position]);
  return props.position.lat && props.position.lng ? (
    <Marker
      position={[Number(props.position.lat), Number(props.position.lng)]}
    />
  ) : null;
};
const Map = (props: Props) => {
  return (
    <MapContainer
      center={[55.81446868470671, 49.1971421910889]}
      zoom={12}
      style={{ height: 500 }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <SearchCenter {...props} />
    </MapContainer>
  );
};
export default Map;
