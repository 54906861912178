import { useQuery, UseQueryResult } from "react-query";
import { ListResponse, situationApi } from "lib/api";
import { Situation } from "lib/types";

interface FilterSituations {
  knsIds?: number[] | number | null;
}
const useFetch = (
  page: number | undefined,
  pageSize: number | undefined,
  filter?: FilterSituations,
): UseQueryResult<ListResponse<Situation>> => {
  const { isLoading, data, refetch, isFetching } = useQuery(
    ["fetchSituation", page, pageSize, filter],
    () => {
      return situationApi.fetch({ page, pageSize, ...filter });
    },
    {
      enabled: Boolean(page) && Boolean(pageSize),
    },
  );
  return { isLoading, data, refetch, isFetching } as UseQueryResult<
    ListResponse<Situation>
  >;
};

export default useFetch;
