import React, { FC } from "react";
import { Skeleton } from "@mui/material";

import { StyledStackRowSituation } from "components/common";

export const SituationListItem: FC = () => {
  return (
    <StyledStackRowSituation>
      <Skeleton sx={{ width: "100%", height: 21 }} />
      <Skeleton sx={{ width: "100%", height: 21 }} />
    </StyledStackRowSituation>
  );
};
