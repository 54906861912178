import React, { FC } from "react";
import clsx from "clsx";

import { Kns } from "lib/types";
import { useCheckKnsError } from "lib/hooks";
import {
  UnlockIcon,
  LockIcon,
  FloodIcon,
  EngineIcon,
  NotifAlarmIcon,
  SensorIcon,
  WaterContainerIcon,
  ElectrIcon,
} from "components/ui";

interface Props {
  kns: Kns;
}
export const ListItemIcons: FC<Props> = ({ kns }) => {
  const {
    isFlood,
    isPitOverflow,
    isEngines,
    isSensor,
    isEmergencyBtn,
    isOpen,
    isPhaseControl,
  } = useCheckKnsError(kns);
  return (
    <div style={{ textAlign: "center" }}>
      {isOpen ? <UnlockIcon /> : <LockIcon />}
      <FloodIcon color={isFlood ? "error" : "disabled"} />
      <EngineIcon type={clsx({ error: isEngines })} />
      <NotifAlarmIcon color={isEmergencyBtn ? "error" : "disabled"} />
      <br />
      <ElectrIcon type={isPhaseControl ? "error" : "disabled"} />
      <SensorIcon color={isSensor ? "error" : "disabled"} />
      <WaterContainerIcon color={isPitOverflow ? "error" : "disabled"} />
    </div>
  );
};
