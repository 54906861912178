import { styled } from "@mui/material/styles";
import {
  Chip,
  MenuItem,
  Skeleton,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { isNil, uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const StyledChip = styled(Chip)`
  margin-top: 6px;
  margin-bottom: -3px;
`;
const ContainerAutocomplete = styled("div")`
  postiton: relative;
`;
const StytledMenuItem = styled(MenuItem)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CustomMenu = styled("div")(({ theme }) => {
  return {
    zIndex: 999,
    position: "absolute",
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
  };
});
const BackgroundMenu = styled("div")(({ theme }) => {
  return {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 999,
  };
});
const StyledTextField = styled(TextField)<{ block?: boolean }>(
  ({ theme, block }) => {
    return {
      zIndex: 999,
      "& .MuiInputBase-root": {
        display: block ? "block" : "inline-flex",
      },
    };
  },
);

type Props = {
  onChange: (id: number | number[] | undefined, obj: any | undefined) => void;
  /**
   * Функция, для подгрузки данных списка.
   *
   * По-умолчанию, когда список только появился на странице,
   * подгружается 50 записей.
   */
  fetch: ((params: any) => Promise<any>) | Function;
  /**
   * Функция для подгрузки одного значения.
   *
   * Используется для добавления с список значения,
   * id которого пришел в value и которое отсутствует
   * с списке подгруженных по-умолчанию.
   */
  fetchSingle: ((id: number, params: any) => Promise<any>) | Function;
  /**
   * Пропсы, при изменении которых необходима переподгрузка данных
   */
  refetchParams?: any;
  multiple?: boolean;
} & Omit<TextFieldProps, "onChange">;

/**
 * Ассинхронный селект с возможностью поиска.
 */
const AutoCompleteSelect = ({ fetch, ...props }: Props) => {
  const inputRef = React.useRef<null | HTMLElement>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [data, setData] = useState([]);
  const [currentValue, setCurrentValue] = useState<string | string[]>("");
  const [search, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearch = async (e: any) => {
    setIsLoading(true);
    const search = e?.target?.value;
    const { onChange } = props;
    if (search === "" || search === undefined) {
      onChange(undefined, undefined);
    }
    try {
      // отправляем запрос с ограничением в 50 результатов
      const { data } = await fetch({
        search,
        page: 1,
        pageSize: 50,
      });
      setData(data);
    } catch (e) {
      setData([]);
    }
    if (props.multiple) {
      setSearch(search);
    } else {
      setCurrentValue(search);
    }
    setIsLoading(false);
  };

  /**
   * Функция для подгрузки данных
   *
   * Если value не указан, то подгружаются первые 50 записей
   * иначе подгружается 50 + 1 запись, связанная с value
   *
   * @param value Текущее значение селекта, которое нужно подгрузить
   */
  const fetchData = useCallback(
    async (value?: number) => {
      try {
        const { disabled, fetchSingle } = props;
        const single = async (value: any, data: any) => {
          // Проп fetchSingle обязателен
          if (typeof fetchSingle !== "function")
            throw new Error("Prop fetchSingle must be a function");
          // Если value - число
          if (typeof value === "number") {
            // Запрашиваем запись
            try {
              if (value !== 0) {
                const loadedValue = await fetchSingle(value, {});
                const isValueLoaded = !isNil(loadedValue);
                // И добавляем эту запись, если она существует
                if (props.multiple) {
                  setCurrentValue((oldValue) => {
                    const isInclide = oldValue.includes(loadedValue?.name);
                    return isInclide
                      ? oldValue
                      : [...(oldValue as string[]), loadedValue?.name ?? ""];
                  });
                } else {
                  setCurrentValue(loadedValue?.name ?? "");
                }
                setData(
                  isValueLoaded ? uniqBy([loadedValue, ...data], "id") : data,
                );
              }
            } catch (error) {
              toast.error(
                `Ошибка: Не удалось получить запись роли ид(${value})`,
              );
              setData(data);
            }
          } else {
            setData(data);
          }
        };
        if (!disabled) {
          setIsLoading(true);
          const { data } = await fetch({
            page: 1,
            pageSize: 50,
          });
          await single(value, data);
          setIsLoading(false);
        } else {
          await single(value, []);
        }
      } catch (error) {}
    },
    [props],
  );

  const handleFetch = async () => {
    setIsLoading(true);
    try {
      // отправляем запрос с ограничением в 50 результатов
      const { data } = await fetch({ page: 1, pageSize: 50 });
      setData(data);
    } catch (e) {
      setData([]);
    }
    setIsLoading(false);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLInputElement>) => {
    props.disabled !== true && setOpen(true);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleSelect = (id: number, item: any) => {
    const { onChange } = props;
    handleCloseMenu();
    if (props.multiple) {
      if (!(props.value as unknown as number[]).includes(id)) {
        setCurrentValue((oldValue) => [...(oldValue as string[]), item.name]);
        onChange &&
          onChange([...(props.value as unknown as number[]), id], item);
        setSearch("");
      }
    } else {
      setCurrentValue(item.name);
      onChange && onChange(id, item);
    }
  };

  const handleDelete = (index: number) => {
    const { onChange, value } = props;
    setCurrentValue(
      (currentValue as string[]).filter((_, idx) => index !== idx),
    );
    onChange &&
      onChange(
        (value as number[]).filter((_, idx) => index !== idx),
        undefined,
      );
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    const id = Number(props.value);
    if (Number.isInteger(id)) {
      fetchData(id);
    } else if (Array.isArray(props.value)) {
      props.value.forEach((val) => {
        const id = Number(val);
        if (Number.isInteger(id)) fetchData(id);
      });
    }
    if (!props.value) {
      setCurrentValue("");
    }
  }, [props.value]);

  const isArray = Array.isArray(props.value);
  return (
    <ContainerAutocomplete>
      <StyledTextField
        inputRef={inputRef}
        onClick={handleOpenMenu}
        block={props.multiple === true}
        InputProps={{
          autoComplete: "off",
          startAdornment:
            props.multiple && Array.isArray(currentValue)
              ? currentValue.map((value, index) => {
                  return (
                    <StyledChip
                      size="small"
                      key={`${value}-${index}`}
                      label={value}
                      onDelete={() => {
                        handleDelete(index);
                      }}
                    />
                  );
                })
              : undefined,
        }}
        {...props}
        onChange={fetchSearch}
        value={isArray ? search : currentValue}
      />
      {open ? (
        <>
          <BackgroundMenu onClick={() => handleCloseMenu()} />
          <CustomMenu style={{ width: inputRef?.current?.offsetWidth ?? 100 }}>
            {isLoading ? (
              <MenuItem>
                <Skeleton variant="text" style={{ width: "100%" }} />
              </MenuItem>
            ) : null}
            {!isLoading &&
              data?.map((item: any) => {
                return (
                  <StytledMenuItem
                    selected={
                      isArray && (props.value as number[]).includes(item.id)
                    }
                    key={`select-${props.id}-${item.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelect(item.id, item);
                    }}>
                    {item.name}
                  </StytledMenuItem>
                );
              })}
          </CustomMenu>
        </>
      ) : null}
    </ContainerAutocomplete>
  );
};

export default AutoCompleteSelect;
