export enum accessTypeEnum {
  admin = 1,
  write = 2,
  read = 3,
}

export type AccessType = 1 | 2 | 3;

export const accessType = {
  1: "админ",
  2: "запись",
  3: "чтение",
};
