import React from "react";

export const ConnectIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.99775" cy="5.54569" r="1.98798" stroke="#CCD2E3" />
      <path
        d="M4.39336 9.23075C3.87846 8.74587 3.47003 8.17023 3.19137 7.5367C2.91271 6.90317 2.76929 6.22416 2.76929 5.53844C2.76929 4.85271 2.91271 4.1737 3.19137 3.54018C3.47003 2.90665 3.87847 2.33101 4.39336 1.84613L5.30775 2.70722C4.91293 3.07902 4.59975 3.52041 4.38608 4.00619C4.1724 4.49197 4.06243 5.01263 4.06243 5.53844C4.06243 6.06424 4.1724 6.5849 4.38608 7.07068C4.59975 7.55646 4.91293 7.99786 5.30775 8.36966L4.39336 9.23075Z"
        fill="#CCD2E3"
      />
      <path
        d="M11.3557 1.84613C12.507 2.82539 13.1538 4.15355 13.1538 5.53844C13.1538 6.92332 12.507 8.25148 11.3557 9.23075L10.3845 8.40464C11.2782 7.64447 11.7803 6.61347 11.7803 5.53844C11.7803 4.46341 11.2782 3.4324 10.3845 2.67224L11.3557 1.84613Z"
        fill="#CCD2E3"
      />
      <path
        d="M2.526 11.0769C1.72516 10.3496 1.0899 9.48615 0.656487 8.53586C0.223074 7.58557 -9.90117e-08 6.56705 0 5.53846C9.90117e-08 4.50987 0.223075 3.49136 0.656488 2.54107C1.0899 1.59078 1.72516 0.727321 2.52601 0L3.46154 0.849647C2.78355 1.46539 2.24574 2.19638 1.87882 3.00089C1.5119 3.8054 1.32304 4.66767 1.32304 5.53846C1.32304 6.40926 1.5119 7.27152 1.87882 8.07603C2.24574 8.88054 2.78355 9.61153 3.46154 10.2273L2.526 11.0769Z"
        fill="#CCD2E3"
      />
      <path
        d="M13.3676 0C15.0039 1.46889 15.9232 3.46113 15.9232 5.53846C15.9232 7.61579 15.0039 9.60803 13.3676 11.0769L12.4617 10.2637C13.8577 9.01046 14.642 7.31075 14.642 5.53846C14.642 3.76617 13.8577 2.06647 12.4617 0.813269L13.3676 0Z"
        fill="#CCD2E3"
      />
      <rect
        x="7.5"
        y="7.03842"
        width="0.995192"
        height="7.96154"
        rx="0.497596"
        fill="#CCD2E3"
      />
    </svg>
  );
};
