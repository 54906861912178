import React, { FC, ChangeEvent, useEffect } from "react";
import { Grid, InputAdornment, Stack, Tooltip } from "@mui/material";
import { Search } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { dispatcherDuck, settingsDuck } from "lib/ducks";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  ConnectionIcon,
  EngineVolumeIcon,
  ErrorNotifIcon,
  FilterIcon,
  StatusWorkIcon,
  VolumeIcon,
  Divider,
} from "components/ui";
import { getQueryParams, setQueryParams } from "query-params-util";

import { StyledStackItem, StyledTextField } from "./styled";
import { ITEM_WIDTH } from "../helpers";
import { OrgUnitTabs } from "components/common";
import { accessTypeEnum } from "lib/enums";

interface Props {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const Header: FC<Props> = observer(({ onSearch }) => {
  const { filter } = dispatcherDuck;
  const profile = settingsDuck.getMe();

  useEffect(() => {
    const { organizationUnitId } = getQueryParams();
    if (organizationUnitId) {
      filter.organizationUnitId = organizationUnitId;
    } else {
      if (!checkAdmin()) {
        filter.organizationUnitId =
          profile?.employee?.organizationUnitId ?? null;
      }
    }
  }, []);

  const checkAdmin = () => {
    return profile?.access?.includes(accessTypeEnum.admin);
  };

  const handleChangeOrgUnit = (
    _event: any,
    organizationUnitId: number | null,
  ) => {
    filter.organizationUnitId = organizationUnitId;
    setQueryParams({ organizationUnitId }, true);
  };

  return (
    <>
      <Grid container item xs={12}>
        <OrgUnitTabs
          value={filter.organizationUnitId}
          onChange={handleChangeOrgUnit}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <StyledTextField
          size="small"
          placeholder="Поиск объекта"
          onChange={onSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1, pt: 1, pb: 1 }}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}>
          <StyledStackItem style={{ width: ITEM_WIDTH[2] }}>
            <ConnectionIcon />
          </StyledStackItem>

          <StyledStackItem style={{ width: ITEM_WIDTH[0] }}>
            <FilterIcon />
          </StyledStackItem>

          <Tooltip title={"Режим работы"} placement="top">
            <StyledStackItem style={{ width: ITEM_WIDTH[1] }}>
              <StatusWorkIcon />
            </StyledStackItem>
          </Tooltip>

          <Tooltip title={"Двигатель"} placement="top">
            <StyledStackItem style={{ width: ITEM_WIDTH[1] }}>
              <EngineVolumeIcon />
            </StyledStackItem>
          </Tooltip>

          <Tooltip title={"Объем"} placement="top">
            <StyledStackItem style={{ width: ITEM_WIDTH[1] }}>
              <VolumeIcon />
            </StyledStackItem>
          </Tooltip>

          <Tooltip title={"Ошибки"} placement="top">
            <StyledStackItem style={{ width: ITEM_WIDTH[3] }}>
              <ErrorNotifIcon />
            </StyledStackItem>
          </Tooltip>

          <StyledStackItem style={{ width: ITEM_WIDTH[4] }}>
            <AccessTimeIcon />
          </StyledStackItem>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
});
