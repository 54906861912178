import React, { FC } from "react";
import {
  DispatcherContainer,
  MapContainer,
  SituationAlertContainer,
} from "containers";
import { RoutesEnum } from "lib/enums";
import { ListKnsJournal, Info, StyledSpaceBeetwen } from "./components";

export const KnsList: FC = () => {
  return (
    <>
      <MapContainer />
      <SituationAlertContainer />
      <DispatcherContainer active={RoutesEnum.KNS} disablePointEvents>
        <ListKnsJournal />
        <StyledSpaceBeetwen />
        <Info />
      </DispatcherContainer>
    </>
  );
};
