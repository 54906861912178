import React, { FC, ReactNode, useEffect } from "react";
import { Grid } from "@mui/material";
import clsx from "clsx";

import { RoutesEnum, RoutesType } from "lib/enums";
import { dispatcherDuck, settingsDuck } from "lib/ducks";

import {
  Header,
  HeaderLink,
  MainContainer,
  ChildrenContainer,
  MenuIconContainer,
  MenuFoldOutlinedStyled,
} from "./components";
import { observer } from "mobx-react-lite";

interface Props {
  active: RoutesEnum;
  children: ReactNode;
  disablePointEvents?: boolean;
}
export const DispatcherContainer: FC<Props> = observer(
  ({ active, children, disablePointEvents = false }) => {
    const { sideBarOpen } = settingsDuck;

    useEffect(() => {
      dispatcherDuck.fetchData();
    }, []);

    useEffect(() => {
      if (sideBarOpen) {
        settingsDuck.setField("hideMenuButterSidebar", false);
      } else {
        settingsDuck.setField("hideMenuButterSidebar", true);
      }
    }, [sideBarOpen]);

    return (
      <MainContainer container>
        <Header container item>
          {sideBarOpen ? null : (
            <MenuIconContainer>
              <MenuFoldOutlinedStyled
                onClick={() => {
                  settingsDuck.setField("sideBarOpen", true);
                }}
              />
            </MenuIconContainer>
          )}
          <Grid item>
            <HeaderLink
              className={clsx({
                active: active === RoutesEnum.JOURNAL,
              })}
              to={`/${RoutesEnum.JOURNAL}`}>
              {RoutesType[RoutesEnum.JOURNAL]}
            </HeaderLink>
          </Grid>
          <Grid item>
            <HeaderLink
              className={clsx({
                active: active === RoutesEnum.KNS,
              })}
              to={`/${RoutesEnum.KNS}`}>
              Объекты
            </HeaderLink>
          </Grid>
          <Grid item>
            <HeaderLink
              className={clsx({
                active: active === RoutesEnum.MONITORING,
              })}
              to={`/${RoutesEnum.MONITORING}`}>
              {RoutesType[RoutesEnum.MONITORING]}
            </HeaderLink>
          </Grid>
          <Grid item>
            <HeaderLink
              className={clsx({
                active: active === RoutesEnum.SITUATION,
              })}
              to={`/${RoutesEnum.SITUATION}`}>
              {RoutesType[RoutesEnum.SITUATION]}
            </HeaderLink>
          </Grid>
        </Header>
        <ChildrenContainer
          container
          item
          xs={12}
          sx={{
            pointerEvents: disablePointEvents ? "none" : "auto",
          }}>
          {children}
        </ChildrenContainer>
      </MainContainer>
    );
  },
);
