import { styled } from "@mui/material/styles";
import { TextField, Grid } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    borderColor: "red",
    "& input": {
      padding: theme.spacing(0.75),
      paddingLeft: 0,
      fontSize: 14,
    },
    "& fieldset": {
      borderColor: theme.palette.primary.light,
    },
  };
});
export const StyledJournalContainer = styled(Grid)(({ theme }) => {
  return {
    flexGrow: 1,
    borderRadius: theme.spacing(1),
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    marginInline: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    pointerEvents: "auto",
    padding: theme.spacing(2),
  };
});

export const StyledSpaceBeetwen = styled("div")(({ theme }) => {
  return {
    minHeight: theme.spacing(1),
    width: "100%",
    display: "flex",
    flexGrow: 1,
  };
});

export const StyledListItemContainer = styled(Grid)(({ theme }) => {
  return {
    height: "calc(100% - 165px)",
    overflow: "auto",
    alignContent: "flex-start",
  };
});

export const StyledKnsName = styled("div")(() => {
  return {
    fontSize: 14,
  };
});

export const StyledAddresses = styled(StyledKnsName)(({ theme }) => {
  return {
    color: theme.palette.customGrey.main,
  };
});

export const StyledStackItem = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const StyledStackListItem = styled(StyledStackItem)(() => {
  return {
    justifyContent: "left",
  };
});

export const ListContainer = styled(Grid)(({ theme }) => {
  return {
    pointerEvents: "auto",
    width: 600,
    height: 625,
    background: theme.palette.background.paper,
    marginTop: theme.spacing(1),
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(2),
    borderRadius: `0px ${theme.spacing(1)} ${theme.spacing(1)} 0px`,
  };
});
