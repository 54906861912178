import { deviceWorkStatusEnum, SituationStatusEnum } from "lib/enums";
import { KnsMessage, Situation } from "lib/types";
import { CommonApiMethod, CommonApi } from "./common";

const MOCK_DATA: KnsMessage = {
  workStatus: deviceWorkStatusEnum.auto,
  emergencyCode: [0],
  liquidLevel: 0,
  reedSwitchInformation: false,
  pumpsCount: 0,
  pumpStatuses: [],
  pumpEfficiency: 0,
} as any as KnsMessage;

const convertDataFromServer = (situation: Situation): Situation => {
  const { knsMessages, kns } = situation;
  const messages =
    Array.isArray(knsMessages) && knsMessages.length !== 0
      ? knsMessages
      : [MOCK_DATA];
  return {
    ...situation,
    kns: {
      ...kns,
      ...messages[0],
    },
    knsMessages: messages,
  };
};

const route = "situation";
const situation: CommonApiMethod = new CommonApi<Situation>(
  route,
  "КНС",
  undefined,
  convertDataFromServer,
);

export default situation;
