export enum SituationStatusEnum {
  DRAFT,
  WORK,
  CLOSED,
  RESOLVED,
}

export const SituationStatus: Record<SituationStatusEnum, string> = {
  [SituationStatusEnum.DRAFT]: "Черновик",
  [SituationStatusEnum.WORK]: "В работе",
  [SituationStatusEnum.CLOSED]: "Закрыт",
  [SituationStatusEnum.RESOLVED]: "Решен",
};
