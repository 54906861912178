export enum DeviceEmergencyCodeEnum {
  nonEmergency = 0,
  engine1Overload = 1,
  engine2Overload = 2,
  engine3Overload = 4,
  enginesLowEfficiency = 8,
  lowerSensorError = 16,
  upperSensorError = 32,
  pitOverflow = 64,
  roomFlooding = 128,
  emergencyButton = 256,
  phaseControlError = 512,
}

export const DeviceEmergencyCode: Record<DeviceEmergencyCodeEnum, string> = {
  [DeviceEmergencyCodeEnum.nonEmergency]: "Безаварийная ситуация",
  [DeviceEmergencyCodeEnum.engine1Overload]:
    "Низкая производительность насоса №1",
  [DeviceEmergencyCodeEnum.engine2Overload]:
    "Низкая производительность насоса №2",
  [DeviceEmergencyCodeEnum.engine3Overload]:
    "Низкая производительность насоса №3",
  [DeviceEmergencyCodeEnum.enginesLowEfficiency]:
    "Низкая общая производительность насосов",
  [DeviceEmergencyCodeEnum.lowerSensorError]: "Ошибка нижнего датчика",
  [DeviceEmergencyCodeEnum.upperSensorError]: "Ошибка верхнего датчика",
  [DeviceEmergencyCodeEnum.pitOverflow]: "Переполнение ямы",
  [DeviceEmergencyCodeEnum.roomFlooding]: "Затопление помещения",
  [DeviceEmergencyCodeEnum.emergencyButton]: "Кнопка аварии",
  [DeviceEmergencyCodeEnum.phaseControlError]: "Ошибка контроля фазы",
};
