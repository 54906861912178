import { useQuery } from "react-query";
import { journalApi } from "../../api";
import { Journal } from "../../types";

const useGet = (id: number | null) => {
  const { isLoading, data, refetch, isFetching } = useQuery(
    ["getJournal", id],
    () => {
      return journalApi.get(id);
    },
    {
      enabled: Boolean(id),
      select: (journal: Journal) => {
        return {
          ...journal,
          date: journal.date ? new Date(journal.date) : null,
          vehicles: journal.vehicles.map((vehicle) => ({
            ...vehicle,
            startDate: vehicle.startDate ? new Date(vehicle.startDate) : null,
            endDate: vehicle.endDate ? new Date(vehicle.endDate) : null,
          })),
        };
      },
    },
  );
  return { isLoading, data: data ?? null, refetch, isFetching };
};

export default useGet;
