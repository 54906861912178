import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Header } from "../../components/layouts";
import { Breadcrumbs, Card } from "../../components/ui";
import { useAddUpdateKns, useKns } from "../../lib/hooks";
import { OrgUnit, Kns } from "../../lib/types";
import InnerForm from "./components/InnerForm";

const route = "/admin/knses";
const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mutation = useAddUpdateKns();
  const kns = useKns(Number(id));
  const handleSubmit = ({ deviceCode, ...kns }: Kns) => {
    mutation.mutate(
      {
        ...kns,
        deviceCode: Number(deviceCode),
        onRepair: false,
      },
      {
        onSuccess: () => {
          navigate(`${route}`);
        },
      },
    );
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <>
      <Header title={id ? "Редактирование КНС" : "Добавление нового КНС"} />
      <Breadcrumbs
        crumbs={[
          { label: "Главная", to: "/" },
          { label: "Список КНС", to: route },
          { label: "КНС" },
        ]}
      />
      <Card>
        <InnerForm
          isLoading={kns.isLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          kns={kns?.data ? (kns.data as unknown as Kns) : ({} as Kns)}
        />
      </Card>
    </>
  );
};
export default Form;
