import React from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { Kns, OrgUnit, Location } from "../../../lib/types";
import { OrgUnitSelect, LocationSelect } from "../../../components/selects";
import { StyledDiv } from "../../../components/ui";

const validationSchema = yup.object({
  name: yup.string().required("Обязательное поле"),
  deviceCode: yup
    .number()
    .required("Обязательное поле")
    .moreThan(0, "Код устройства не может быть отрицательным"),
  pumpsCount: yup
    .number()
    .required("Обязательное поле")
    .moreThan(0, "Кол-во насосов должно быть больше 0"),
});

type Props = {
  handleSubmit: (kns: Kns) => void;
  handleCancel: () => void;
  kns: Kns;
  isLoading: boolean;
};
const InnerForm = ({ handleSubmit, kns, isLoading, handleCancel }: Props) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: kns,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e);
  };
  const isSend = Boolean(formik.submitCount);
  return (
    <StyledDiv>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="name"
              name="name"
              label="Наименование"
              type="text"
              InputProps={{
                autoComplete: "off",
              }}
              value={formik.values?.name ?? ""}
              onChange={formik.handleChange}
              error={
                (formik.touched.name || isSend) && Boolean(formik.errors.name)
              }
              helperText={(formik.touched.name || isSend) && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="deviceCode"
              name="deviceCode"
              label="Код устройства"
              type="number"
              InputProps={{
                autoComplete: "off",
              }}
              value={formik.values?.deviceCode ?? ""}
              onChange={formik.handleChange}
              error={
                (formik.touched.deviceCode || isSend) &&
                Boolean(formik.errors.deviceCode)
              }
              helperText={
                (formik.touched.deviceCode || isSend) &&
                formik.errors.deviceCode
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OrgUnitSelect
              value={formik.values?.organizationUnitId ?? ""}
              onChange={(
                organizationUnitId: number | number[] | undefined,
                _: OrgUnit | undefined,
              ) => {
                formik.setFieldValue("organizationUnitId", organizationUnitId);
              }}
              error={
                (formik.touched.organizationUnitId || isSend) &&
                Boolean(formik.errors.organizationUnitId)
              }
              helperText={
                (formik.touched.organizationUnitId || isSend) &&
                formik.errors.organizationUnitId
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocationSelect
              value={formik.values?.locationId ?? ""}
              onChange={(
                locationId: number | number[] | undefined,
                _orgUnit: Location | undefined,
              ) => {
                formik.setFieldValue("locationId", locationId);
              }}
              error={
                (formik.touched.locationId || isSend) &&
                Boolean(formik.errors.locationId)
              }
              helperText={
                (formik.touched.locationId || isSend) &&
                formik.errors.locationId
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="pumpsCount"
              name="pumpsCount"
              label="Кол-во насосов"
              type="number"
              InputProps={{
                autoComplete: "off",
              }}
              value={formik.values?.pumpsCount ?? ""}
              onChange={formik.handleChange}
              error={
                (formik.touched.pumpsCount || isSend) &&
                Boolean(formik.errors.pumpsCount)
              }
              helperText={
                (formik.touched.pumpsCount || isSend) &&
                formik.errors.pumpsCount
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              type="submit">
              Cохранить
            </LoadingButton>
            <LoadingButton
              onClick={handleCancel}
              loading={isLoading}
              style={{ float: "right" }}>
              Отменить
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </StyledDiv>
  );
};
export default InnerForm;
