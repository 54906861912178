import { createTheme } from "@mui/material/styles";
export const THEME_PALETTE = {
  black: {
    main: "#3C3939",
  },
  primary: {
    light: "#E5F3FB",
    main: "#6C98DA",
    contrastText: "white",
  },
  error: {
    light: "#fce3e3",
    main: "#EB4343",
  },
  grey: {
    light: "#f7f8fb",
    main: "#9B9797",
    medium: "#CCD2E3",
    border: "#e0e0e0",
  },
  system: {
    grey: "#F5F3F3",
    green: {
      light: "#7ed97e26",
      main: "#7ED97E",
    },
  },
};

type SystemColor = {
  grey: string;
  green: {
    light: string;
    main: string;
  };
};

declare module "@mui/material/styles" {
  interface Palette {
    customGrey: Palette["primary"] & { medium: string };
    black: Palette["primary"];
    system: SystemColor;
  }

  interface PaletteOptions {
    customGrey?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
    system?: SystemColor;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: [
      "Inter",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: THEME_PALETTE.primary,
    error: THEME_PALETTE.error,
    customGrey: THEME_PALETTE.grey,
    black: THEME_PALETTE.black,
    system: THEME_PALETTE.system,
  },
});
export default theme;
