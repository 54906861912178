import React, { ChangeEvent, FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";

import { JournalContainer } from "containers";
import { journalDuck } from "lib/ducks";
import { Kns } from "lib/types";

import { ListContainer, StyledJournalContainer } from "./styled";
import { Header } from "./Header";
import { ListItems } from "./ListItems";

export const ListKnsJournal: FC = observer(() => {
  const [search, setSearch] = useState<string>("");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e?.target?.value ?? "");
  };
  return (
    <Grid container>
      <ListContainer container item>
        <Header onSearch={handleSearch} />
        <ListItems search={search} />
      </ListContainer>
      {journalDuck.activeKns && (
        <StyledJournalContainer item sx={{ maxWidth: "calc(100% - 625px)" }}>
          <JournalContainer
            kns={journalDuck.activeKns as Kns}
            maxHeight="calc(482px)"
          />
        </StyledJournalContainer>
      )}
    </Grid>
  );
});
